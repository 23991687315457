export function useKmzaGet4ClickEvents() {
  const getCrossmailScript = (trackerId: string) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = `//get4click.ru/wrapper.php?method=container&shopId=${trackerId}`;

    return script;
  };

  const injectCrossmailScript = () => {
    const SHOP_ID = '1493';

    document.body.appendChild(getCrossmailScript(SHOP_ID));
  };

  return {
    injectCrossmailScript,
  };
}
