import { type ProcessingMapperOptions, ProcessingMapper } from '../base/processing-mapper';
import { MomentMapper } from '../moments/moment';
import { SeoTextMapper } from '../seo/seo-text';
import { WatchingItemMapper } from '../watching-items/watching-item';
import { ActorMapper } from './actor';
import type { ApiMedia } from './content-types/media';
import { CountryMapper } from './country';
import { DirectorMapper } from './director';
import { GenreMapper } from './genre';
import { ProducerMapper } from './producer';
import { StarringMapper } from './starring';
import type { Media } from './types/media';
import { WriterMapper } from './writer';

export class MediaMapper {
  static mapMany(items: ApiMedia[], options: ProcessingMapperOptions = { freezeData: true }): Media[] {
    return ProcessingMapper.process(
      items.map((item) => MediaMapper.map(item, options)),
      options,
    );
  }

  public static map(media: ApiMedia, options: ProcessingMapperOptions = { freezeData: true }): Media {
    const {
      actors,
      age_limit,
      content_match_percent_by_profile,
      content_type,
      countries,
      default_moment_playlist_id,
      default_moment_playlist_slug,
      description_html,
      directors,
      genres,
      imdb_rating,
      imdb_rating_vote_count,
      in_user_collection,
      kinopoisk_rating,
      kinopoisk_rating_vote_count,
      license_locked_info,
      license_date_start,
      license_date_end,
      main_moment,
      moments,
      producers,
      release_date,
      seo_text,
      smoking_ad_text,
      starring,
      title_en,
      watching_item,
      writers,
      can_create_ugc,
      serial_id,
      serial_title,
      access_kind,
      ...rest
    } = media;

    return ProcessingMapper.process(
      {
        ...rest,
        accessKind: access_kind,
        actors: actors ? ActorMapper.mapMany(actors) : [],
        ageLimit: age_limit,
        serialId: serial_id,
        serialTitle: serial_title,
        contentMatchPercent: content_match_percent_by_profile,
        contentType: content_type,
        countries: countries ? CountryMapper.mapMany(countries) : [],
        defaultMomentPlaylistId: default_moment_playlist_id,
        descriptionHtml: description_html,
        directors: directors ? DirectorMapper.mapMany(directors) : [],
        genres: genres ? GenreMapper.mapMany(genres) : [],
        imdbRating: imdb_rating,
        imdbRatingVoteCount: imdb_rating_vote_count,
        inUserCollection: in_user_collection,
        defaultMomentPlaylistSlug: default_moment_playlist_slug,
        kinopoiskRating: kinopoisk_rating,
        kinopoiskRatingVoteCount: kinopoisk_rating_vote_count,
        licenseLockedInfo: license_locked_info,
        licenseDateStart: license_date_start,
        licenseDateEnd: license_date_end,
        mainMoment: main_moment,
        moments: moments ? MomentMapper.mapMany(moments) : [],
        producers: producers ? ProducerMapper.mapMany(producers) : [],
        releaseDate: release_date,
        seoText: seo_text ? SeoTextMapper.map(seo_text) : undefined,
        smokingAdText: smoking_ad_text,
        starring: starring ? StarringMapper.mapMany(starring) : [],
        titleEn: title_en,
        watchingItem: watching_item ? WatchingItemMapper.map(watching_item) : undefined,
        writers: writers ? WriterMapper.mapMany(writers) : [],
        canCreateUGC: can_create_ugc,
      },
      options,
    );
  }
}
