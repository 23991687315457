import type { ApiMoment } from '../../moments/moment-types/moment';
import type { ApiSeoText } from '../../seo/seo-types/seo-text';
import type { ApiWatchingItem } from '../../watching-items/watching-item-types';
import { AvailableContentType, MediaContentType } from '../types/content-type';
import type { ApiActor } from './actor';
import type { ApiCountry } from './country';
import type { ApiDirector } from './director';
import type { ApiGenre } from './genre';
import type { ApiMainMoment } from './main-moment';
import type { ApiProducer } from './producer';
import type { ApiStarring } from './starring';
import type { ApiWriter } from './writer';

export type AbstractContentAccessKind = 'all_users' | 'register' | 'subscription' | 'partly_free';

export type ContentAccessKind = AbstractContentAccessKind;

export enum ContentAccessTypes {
  AllUsers = 'all_users',
  Register = 'register',
  Subscription = 'subscription',
  PartlyFree = 'partly_free',
}

export interface ApiMedia {
  access_kind: ContentAccessKind;
  actors: ApiActor[];
  season_number?: number;
  age_limit: number;
  serial_title?: string;
  audio?: string[];
  availability: AvailableContentType;
  background: string;
  can_create_ugc: boolean;
  content_match_percent_by_profile?: string;
  content_type: MediaContentType;
  countries: ApiCountry[];
  serial_id?: string;
  default_moment_playlist_slug?: string;
  default_moment_playlist_id?: string;
  description: string;
  description_html?: string;
  directors: ApiDirector[];
  duration: number;
  genres: ApiGenre[];
  id: string;
  image?: string;
  imdb_rating: number;
  imdb_rating_vote_count: number;
  in_user_collection: boolean;
  kinopoisk_rating: number;
  kinopoisk_rating_vote_count: number;
  license_locked_info?: string;
  license_date_start?: string;
  license_date_end?: string;
  logo?: string;
  main_moment?: ApiMainMoment;
  moments?: ApiMoment[];
  poster: string;
  preview?: string;
  producers?: ApiProducer[];
  release_date: string;
  seo_text?: ApiSeoText;
  slug: string;
  smoking_ad_text?: boolean;
  starring?: ApiStarring[];
  summary?: string;
  synopsis?: string;
  tagline?: string;
  title: string;
  title_en?: string;
  video?: number[];
  watching_item?: ApiWatchingItem;
  writers?: ApiWriter[];
  year: number;
}
