import { BaseOAuthProvider } from './base-provider';
import { OAuthProviderType, OAuthState } from './o-auth';
import type { OAuthResponse } from './types/o-auth';

export class YandexOAuthProvider extends BaseOAuthProvider {
  type = OAuthProviderType.Yandex;
  responseQueryName = 'access_token';

  createOAuthUri(): string {
    const url = new URL('https://oauth.yandex.ru/authorize');
    url.searchParams.set('response_type', 'token');
    url.searchParams.set('force_confirm', 'yes');
    url.searchParams.set('display', 'popup');
    url.searchParams.set('redirect_uri', this.redirectUri);
    url.searchParams.set('client_id', String(useRuntimeConfig().public.OAUTH_YANDEX_CLIENT_ID));
    return url.href;
  }

  public override handleOAuthResponseUri(uri = window.location.href) {
    const token = new RegExp(`${this.responseQueryName}=([^&]+)`).exec(uri)?.[1];
    const state = token ? OAuthState.Success : OAuthState.Failed;
    const response = { provider: this.type, state, token } as OAuthResponse;
    window.opener.postMessage(JSON.stringify(response), window.location.origin);
  }
}
