import { ProcessingMapper } from '../base/processing-mapper';
import { PageSlug } from '../subscriptions/types/page-slug';
import type { PageStrings } from '../subscriptions/types/page-strings';
import type { ApiPageStrings } from './frontend-page';

export class PageStringsMapper {
  static map({ frontend_texts, slug }: ApiPageStrings): PageStrings {
    const strings = {} as PageStrings;

    if (slug === PageSlug.OffersGeneralInfo) {
      strings[slug] = {
        bottomText: frontend_texts?.offers_screen_bottom_text || '',
        subtitle: frontend_texts?.offers_screen_subtitle || '',
        title: frontend_texts?.offers_screen_title || '',
      };
    }

    if (slug === PageSlug.SubscriptionLKInfo.split('/')[0]) {
      strings[PageSlug.SubscriptionLKInfo] = {
        cardTitle: frontend_texts?.lk_subscription_title || '',
      };
    }

    if (slug === PageSlug.CancelSubscriptionPopup) {
      strings[slug] = {
        title: frontend_texts?.popup_title || '',
        description: frontend_texts?.popup_description || '',
        unsubscribeBtnText: frontend_texts?.popup_unsubscribe_button || '',
        keepSubscriptionBtnText: frontend_texts?.popup_keep_subscription_button || '',
      };
    }

    if (slug === PageSlug.CancelSubscriptionObjection) {
      strings[slug] = {
        titleWithoutBonus: frontend_texts?.title_without_bonus || '',
        titleWithBonus: frontend_texts?.title_with_bonus || '',
        subtitleWithoutBonus: frontend_texts?.subtitle_without_bonus || '',
        subtitleWithBonus: frontend_texts?.subtitle_with_bonus || '',
        acceptButtonWithoutBonus: frontend_texts?.accept_button_without_bonus || '',
        acceptButtonWithBonus: frontend_texts?.accept_button_with_bonus || '',
        refuseButtonWithoutBonus: frontend_texts?.refuse_button_without_bonus || '',
        refuseButtonWithBonus: frontend_texts?.refuse_button_with_bonus || '',
      };
    }

    return ProcessingMapper.process(strings);
  }
}
