export enum AppTheme {
  Dark = 'dark',
  Light = 'light',
}

export const DEFAULT_THEME = AppTheme.Dark;

export const getCSSClassByThemeName = (theme: AppTheme): string => {
  switch (theme) {
    case AppTheme.Light:
      return 'themeLight';
    case AppTheme.Dark:
    default:
      return 'themeDark';
  }
};

const themes = [AppTheme.Dark, AppTheme.Light];

export const validateTheme = (theme: unknown): theme is AppTheme => themes.includes(theme as AppTheme);
