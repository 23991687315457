import { AppRoute } from '@/platform/router/routes';

import useProfile from '../code/profile/use-profile';

export const parentalProtectedRoutes = new Set([
  AppRoute.AccountInfo,
  AppRoute.AccountBonuses,
  AppRoute.AccountDevices,
  AppRoute.AccountSubscriptionInfo,
  AppRoute.AccountSubscriptionManage,
  AppRoute.AccountBonusesPromocode,
  AppRoute.AccountBonusesReferral,
  AppRoute.Account,
  AppRoute.AccountLanguage,
  AppRoute.Offers,
]);

const isParentalProtectedRoute = (route: AppRoute) => parentalProtectedRoutes.has(route);

/**
 * В детском профиле, не разрешено управление профилем и посещение некоторых роутов
 */
export default defineNuxtRouteMiddleware((route) => {
  const { isAllowedManageProfile } = useProfile();

  if (!isAllowedManageProfile.value && isParentalProtectedRoute(route.name as AppRoute)) {
    return navigateTo({
      name: AppRoute.AccountProfiles,
    });
  }
});
