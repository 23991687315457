import { isClient } from '@vueuse/core';

import { AppTheme, DEFAULT_THEME, getCSSClassByThemeName, validateTheme } from '@/code/layout/app-theme';
import { CookieName } from '@/platform/cookies/cookies';
import { RouteQuery } from '@/platform/router/query';

import useAppCookie from '../../platform/cookies/use-app-cookie';
import { usePageMeta } from '../seo/use-page-meta';

export function useAppTheme() {
  const appThemeCookie = useAppCookie(CookieName.AppTheme, { default: () => DEFAULT_THEME });
  const route = useRoute();
  const { useHeadRaw } = usePageMeta();

  const setAppTheme = (theme: AppTheme) => {
    const oldClassName = getCSSClassByThemeName(appThemeCookie.value);

    const className = getCSSClassByThemeName(theme);
    appThemeCookie.value = theme;

    if (isClient) {
      document.documentElement.classList.remove(oldClassName);
      document.documentElement.classList.add(className);
      return;
    }

    useHeadRaw({ htmlAttrs: { class: className } });
  };

  const initializeTheme = () => {
    const themeQuery = route.query[RouteQuery.AppTheme];
    const isValidQueryTheme = validateTheme(themeQuery);

    if (isValidQueryTheme) {
      setAppTheme(themeQuery);
      return;
    }

    setAppTheme(appThemeCookie.value);
  };

  return {
    initializeTheme,
    setAppTheme,
  };
}
