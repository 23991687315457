import * as playerHelpers from '@package/media-player/src/player/helpers';
import { Disposable, getFirstElement, safeReplaceAll } from '@package/sdk/src/core';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

import DateService from '@/platform/base/date';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { AppLanguage } from '@/platform/localization/language';
import type { TranslationKey } from '@/platform/localization/use-locale';
import { RouteQuery } from '@/platform/router/query';
import { AppVariation } from '@/platform/variation/interfaces';
import useHostVariation, { availableLanguages } from '@/platform/variation/use-host-variation';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';

import en from '../../../../translations/web/en.json';
import hy from '../../../../translations/web/hy.json';
import ru from '../../../../translations/web/ru.json';

function pluralize(key: TranslationKey, count: number): TranslationKey {
  let n = Math.abs(count);
  n = n % 100;

  if (n >= 5 && n <= 20) {
    return (key + '5') as TranslationKey;
  }

  n = n % 10;

  if (n === 1) {
    return (key + '1') as TranslationKey;
  }

  if (n >= 2 && n <= 4) {
    return (key + '2') as TranslationKey;
  }

  return (key + '5') as TranslationKey;
}

function createCommonLocalizeDictionary(lang: AppLanguage): Record<string, string> {
  if (lang === AppLanguage.RU) {
    return ru;
  }

  if (lang === AppLanguage.EN) {
    return en;
  }

  if (lang === AppLanguage.AM) {
    return hy;
  }

  return {};
}

class LocaleService extends Disposable {
  public readonly translations: Record<string, string>;

  public entries: Record<string, string> = {};

  constructor(lang: AppLanguage) {
    super();

    this.translations = createCommonLocalizeDictionary(lang);
  }

  public setEntries = (entries: Record<string, string>) => {
    this.entries = entries;
  };

  public translate = (key: TranslationKey | string, params: Record<string, string | number> = {}) => {
    let translation = this.translations[key];

    if (!translation) {
      return key;
    }

    const allEntries = Object.assign(params, this.entries);
    const entries = Object.entries(allEntries);

    entries.forEach(([keyToReplace, value]) => {
      translation = safeReplaceAll(translation, `{{ ${keyToReplace} }}`, value?.toString());
      translation = safeReplaceAll(translation, `{{${keyToReplace}}}`, value?.toString());
    });

    return translation;
  };

  /**
   * Перевод ключей с числительными
   *
   * {
   *   away1: 'Вас не было {{ value }} день',
   *   away2: 'Вас не было {{ value }} дня',
   *   away5: 'Вас не было {{ value }} дней'
   * }
   * @example tPlural('away', 2, { value: 32 }) -> 'Вас не было 32 дня'
   *
   * @param key
   * @param count
   * @param params
   * @returns {string}
   */
  public tPlural = (key: TranslationKey | string, count: number, params?: Record<string, string | number>) => {
    const pluralKey = pluralize(key as TranslationKey, count);

    return this.translate(pluralKey, params);
  };

  public translateContentButtonText = (
    data: string | { key: TranslationKey | string; count?: number; params?: Record<string, string | number> },
  ) => {
    if (typeof data === 'string') {
      return data;
    }

    const { key, count, params } = data;

    if (count) {
      const pluralKey = pluralize(key as TranslationKey, count);
      return this.translate(pluralKey, params);
    }

    return this.translate(key, params);
  };
}

const getDefaultLanguage = (variation: AppVariation) => getFirstElement(availableLanguages[variation]) as AppLanguage;

const allLanguages = [AppLanguage.EN, AppLanguage.RU, AppLanguage.AM];

const validateLanguage = (lang: unknown): lang is AppLanguage => allLanguages.includes(lang as AppLanguage);

export default defineNuxtPlugin({
  name: 'i18n',
  setup() {
    const { hostName, baseTemplateTitle, appLanguage, appVariation } = useHostVariation();
    const langCookie = useAppCookie(CookieName.AppLanguage);
    const layoutStore = useLayoutStore();
    const route = useRoute();
    const { currentOfferCurrencySign } = storeToRefs(useOffersStore());

    const doSetupLanguage = (lang: AppLanguage) => {
      langCookie.value = lang;
      // @ts-ignore
      playerHelpers.setPlayerLang(lang);
      layoutStore.setCurrentAppLanguage(lang);
    };

    const setupAppLanguage = () => {
      const langQuery = route.query[RouteQuery.AppLang];

      if (validateLanguage(langQuery)) {
        doSetupLanguage(langQuery);
        return;
      }

      if (validateLanguage(langCookie.value)) {
        doSetupLanguage(langCookie.value);
        return;
      }

      const appLanguage = getDefaultLanguage(appVariation);

      doSetupLanguage(appLanguage);
    };

    setupAppLanguage();

    const localeService = new LocaleService(appLanguage.value);
    const dateService = new DateService(appLanguage.value);

    // Валюта оффера может меняться (в любой момент), оперативно на это реагируем, и обновляем локаль, если знак валюты поменялся
    watch(
      currentOfferCurrencySign,
      () => {
        localeService.setEntries({
          brandName: hostName,
          templateTitle: baseTemplateTitle.value,
          appCurrency: currentOfferCurrencySign.value,
        });
      },
      { immediate: true },
    );

    return {
      provide: {
        formatDate: dateService.format,
        formatDateWithCustomFormat: dateService.formatDateWithCustomFormat,
        t: localeService.translate,
        tPlural: localeService.tPlural,
        translateContentButtonText: localeService.translateContentButtonText,
        formatDuration: dateService.formatDuration,
      },
    };
  },
});
