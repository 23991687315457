import type { ApiSitemapPage } from './seo-types/sitemap-page';
import { SitemapPageItemMapper } from './sitemap-page-item';
import type { SitemapPage } from './types/sitemaps';

export class SitemapPageMapper {
  static map({ items }: ApiSitemapPage): SitemapPage {
    return {
      items: items ? SitemapPageItemMapper.mapMany(items) : [],
    };
  }
}
