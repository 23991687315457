import routerOptions0 from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._y2pnvhp6tm3yivnb4cdlskys5u/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/srv/app/apps/web/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}