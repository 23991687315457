import { type Channel, type Media, AvailableContentType } from '@package/sdk/src/api';
import { AppDateManager } from '@PLAYER/player/modules/date/date-manager';
import { tPlural, translate } from '@PLAYER/player/modules/localization/translate';
import { differenceInDays, getHours, getMinutes, parseISO } from 'date-fns';

const statuses = [
  AvailableContentType.UNAVAILABLE,
  AvailableContentType.UNAVAILABLESOON,
  AvailableContentType.AVAILABLESOON,
];
const MIN_LICENSE_DURATION_IN_DAYS = 14;

const daysLeft = (date: string): number => {
  const dayInMilliseconds = 1000 * 60 * 60 * 24;

  return Math.round((new Date(date).getTime() - new Date().setHours(0, 0, 0, 0)) / dayInMilliseconds);
};

interface GetAvailabilityMessageOptions {
  isActiveSubscription?: boolean;
}

export default function useContentAvailability() {
  const getUnavailableSoonText = (daysCount: number): string => {
    return tPlural('availability.unavailableSoon', daysCount, { days: daysCount });
  };

  const getAvailableSoonText = (date: string, time: string, withHourOrMinute: boolean): string => {
    if (withHourOrMinute) {
      return translate('availability.availableSoonDateAndTime', { date, time });
    }

    return translate('availability.availableSoonDate', { date });
  };

  const isUnavailable = (content?: Media) => {
    const availability: string | AvailableContentType = content?.availability as string;

    return availability === AvailableContentType.UNAVAILABLE || availability === 'timeshift_available';
  };

  const isAvailableSoon = (content?: Media) => content?.availability === AvailableContentType.AVAILABLESOON;

  const isUnavailableSoon = (content?: Media) => content?.availability === AvailableContentType.UNAVAILABLESOON;

  const isAvailable = (content?: Media) =>
    content?.availability === AvailableContentType.AVAILABLE || isUnavailableSoon(content);

  const getAvailabilityMessage = (content?: Media | Channel, options: GetAvailabilityMessageOptions = {}): string => {
    const { isActiveSubscription } = options;

    const media = content as Media;
    const channel = content as Channel;

    const availability: string | AvailableContentType = content?.availability as string;
    /* eslint-disable */
    switch (availability) {
      case AvailableContentType.UNAVAILABLE:
      case 'timeshift_available':
        return media.licenseLockedInfo || channel.lockedInfo || translate('availability.unavailable');
      case AvailableContentType.UNAVAILABLESOON:
        const licenseDateStart = parseISO(media.licenseDateStart as string);
        const licenseDateEnd = parseISO(media.licenseDateEnd as string);
        const diff = differenceInDays(licenseDateEnd, licenseDateStart);

        // елси контент скоро недоступен и длина лицензии меньше двух недель, то не показываем сообщение о заканчивающейся лицензии
        if (diff <= MIN_LICENSE_DURATION_IN_DAYS) {
          return '';
        }

        return isActiveSubscription ? getUnavailableSoonText(daysLeft(media.licenseDateEnd as string)) : '';
      case AvailableContentType.AVAILABLESOON: {
        const licenseDateStart = parseISO(media.licenseDateStart as string);

        const hour = getHours(licenseDateStart);
        const minute = getMinutes(licenseDateStart);
        const hasHourOrMinute = Boolean(hour || minute);

        const date = AppDateManager.format(licenseDateStart);
        const time = AppDateManager.formatDateWithCustomFormat(licenseDateStart, 'H:mm');

        return getAvailableSoonText(date, time, hasHourOrMinute);
      }
      default:
        return '';
    }
    /* eslint-enable */
  };

  const showAvailabilityOverlay = (content: Media) => {
    return statuses.includes(content?.availability);
  };

  return {
    getAvailabilityMessage,
    isAvailable,
    isUnavailable,
    isAvailableSoon,
    isUnavailableSoon,
    showAvailabilityOverlay,
  };
}
