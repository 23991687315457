import { isUndefinedOrNull, UnexpectedComponentStateError } from '@package/sdk/src/core';

export enum AppCurrency {
  Rub = '₽',
  Dram = '֏',
  USDollar = '$',
}

export const getCurrencyByText = (currency: 'RUB' | 'AMD' | 'USD' | string): AppCurrency | string => {
  if (!currency) {
    return AppCurrency.Rub;
  }

  switch (currency) {
    case 'AMD':
      return AppCurrency.Dram;
    case 'USD':
      return AppCurrency.USDollar;
    case 'RUB':
      return AppCurrency.Rub;
    // По дефолту, вернем просто саму валюту без знака (так кажется логичнее)
    default:
      return currency;
  }
};

interface WithAppCurrencyOptions {
  currencyText: 'RUB' | 'AMD';
  value?: string | number | undefined;
}

export function withAppCurrency(options: WithAppCurrencyOptions) {
  const { currencyText, value } = options;

  const currency = getCurrencyByText(currencyText);

  if (isUndefinedOrNull(value)) {
    throw new UnexpectedComponentStateError('value');
  }

  return `${value} ${currency}`;
}
