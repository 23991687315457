import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useProfile from '@/code/profile/use-profile';
import { AppRoute } from '@/platform/router/routes';
import { useSessionStore } from '@/stores/use-session-store';

const availableRoutes = [
  AppRoute.Index,
  AppRoute.Movies,
  AppRoute.MovieCard,
  AppRoute.Serials,
  AppRoute.SerialCard,
  AppRoute.SerialEpisodeCard,
  AppRoute.SerialSeasonCard,
  AppRoute.TV,
  AppRoute.TVChannel,
  AppRoute.MyCollection,
  AppRoute.Playlists,
  AppRoute.MyChannel,
  AppRoute.MyChannelMoment,
  AppRoute.PlaylistItem,
  AppRoute.Search,
  AppRoute.Compilations,
  AppRoute.CompilationsItem,
  AppRoute.MyCollectionContent,
  AppRoute.MyCollectionKinom,
  AppRoute.MovieFilter,
  AppRoute.SerialFilter,
  AppRoute.Account,
  AppRoute.AccountInfo,
  AppRoute.AccountProfiles,
  AppRoute.AccountBonusesPromocode,
  AppRoute.AccountDevices,
  AppRoute.AccountLanguage,
  AppRoute.AccountSubscriptionInfo,
  AppRoute.AccountSubscriptionManage,
];

export interface ConnectTvStep {
  imageSrc: string;
  number?: string;
  text: string;
}

export const useConnectTvHeaderButton = () => {
  const sessionsStore = useSessionStore();
  const { isAuth } = storeToRefs(sessionsStore);
  const { isMainProfile } = useProfile();

  const currentRoute = useRoute();

  const canShowRoute = computed(() => availableRoutes.includes(currentRoute.name as AppRoute));

  const isConnectTvHeaderButtonShown = computed(() => isAuth.value && canShowRoute.value && isMainProfile.value);

  return { isConnectTvHeaderButtonShown };
};
