import { ApplicationError } from '@package/sdk/src/core';

interface ImageFetchingErrorData {
  pageUrl: string;
  imageUrl: string;
}

const IMAGE_FETCHING_ERROR_NAME = 'ImageFetchingError';

export class ImageFetchingError extends ApplicationError {
  public override readonly name = IMAGE_FETCHING_ERROR_NAME;

  constructor(public readonly data: ImageFetchingErrorData) {
    super(`Image not found at CDN. Page: ${data.pageUrl}. Link: ${data.imageUrl}`);
  }

  public toJSON(): Record<string, any> {
    const { data } = this;

    return data;
  }
}
