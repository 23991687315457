export enum PromoOfferErrorType {
  EntityNotFound = 'entity_not_found',
  BonusRestrictedForCountry = 'bonus_restricted_for_country',
  BonusDateIsInvalid = 'bonus_date_is_invalid',
  UserFromPartner = 'user_from_partner',
  UserHasExternalSubscription = 'user_has_external_subscription',
  UserHadSubscriptionBefore = 'user_had_subscription_before',
  BonusIsNotProfitable = 'bonus_is_not_profitable',
  BonusTypeIsInvalid = 'bonus_type_is_invalid',
  BonusAlreadyActivated = 'bonus_already_activated',
  BonusAlreadyRejected = 'bonus_already_rejected',
  BonusIsInvalid = 'bonus_is_invalid',
}
