import { AnalyticEventNameNew } from '@package/sdk/src/analytics';

import IconAppStoreEn from '@/components/icons/IconAppStoreEn.vue';
import IconGoogleStoreEn from '@/components/icons/IconGoogleStoreEn.vue';
import useLocale from '@/platform/localization/use-locale';

import { AppLanguage } from '../localization/language';
import { type CompanyInformationMultipleLanguages, type ISocialMediaLink } from './variation-types';

const TECH_SUPPORT_EMAIL = 'help@viju.am';
const SocialMediaLink = {};
const OFFICE_ADDRESS_RU = 'Республика Армения, г. Ереван, проспект Саят-Новы, 19/1, офис 129';
const OFFICE_ADDRESS_EN = 'Republic of Armenia, Yerevan, Sayat-Nova Avenue, 19/1, office 129';

export default function useAmVariation() {
  const { translate } = useLocale();

  const amCompanyInformation: CompanyInformationMultipleLanguages = {
    [AppLanguage.RU]: {
      supportEmail: TECH_SUPPORT_EMAIL,
      contacts: [
        {
          title: 'Email:',
          value: TECH_SUPPORT_EMAIL,
        },
      ],
      socials: SocialMediaLink,
      officeAddress: OFFICE_ADDRESS_RU,
      workTime: 'Понедельник — пятница с 9:00 до 18:00',
      companyInformation: ['OOO «Ви Бродкастинг»', 'ИНН: 02867688', 'ОГРН: 273.110.1245813'],
    },
    [AppLanguage.EN]: {
      supportEmail: TECH_SUPPORT_EMAIL,
      contacts: [
        {
          title: 'Email:',
          value: TECH_SUPPORT_EMAIL,
        },
      ],
      socials: SocialMediaLink,
      officeAddress: OFFICE_ADDRESS_EN,
      workTime: 'Monday - Friday from 9:00 to 18:00',
      companyInformation: ['LLC «V Broadkasting»', 'TIN: 02867688', 'RN: 273.110.1245813'],
    },
  };
  const amSocialMediaLinks: ISocialMediaLink[] = [];

  const getAmCompanyInformation = (appLanguage: AppLanguage) => {
    return amCompanyInformation[appLanguage] || amCompanyInformation[AppLanguage.EN];
  };

  const amStoreLinks: any[] = [
    {
      link: 'https://viju.onelink.me/Da8L/ru4aemva',
      title: translate('ariaLabel.socialMedia.goToStore', { storeName: 'Google Play' }),
      analyticEventName: AnalyticEventNameNew.GotoGooglePlay,
      icon: IconGoogleStoreEn,
    },
    {
      link: 'https://viju.onelink.me/Da8L/ru4aemva',
      title: translate('ariaLabel.socialMedia.goToStore', { storeName: 'App Store' }),
      analyticEventName: AnalyticEventNameNew.GotoAppStore,
      icon: IconAppStoreEn,
    },
  ];
  return { amStoreLinks, amCompanyInformation, getAmCompanyInformation, amSocialMediaLinks };
}
