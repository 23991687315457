<template>
  <svg width="152" height="48" viewBox="0 0 152 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="151" height="47" rx="9.5" stroke="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.2076 11.2891C31.3085 12.6076 30.8916 13.9135 30.0452 14.9295C29.6399 15.4336 29.1255 15.8391 28.5408 16.1156C27.9561 16.392 27.3163 16.5322 26.6695 16.5256C26.6282 15.8915 26.7123 15.2556 26.917 14.654C27.1217 14.0525 27.4429 13.4972 27.8623 13.0199C28.7201 12.0429 29.9145 11.4249 31.2076 11.2891ZM33.5666 20.8857C33.1369 21.6429 32.9057 22.4964 32.8945 23.3668C32.8955 24.346 33.1848 25.3032 33.7262 26.1191C34.2676 26.935 35.0372 27.5734 35.939 27.9548C35.5844 29.1067 35.05 30.1952 34.3554 31.1801C33.4226 32.5754 32.4446 33.9386 30.8924 33.9638C30.1541 33.9808 29.6558 33.7685 29.1365 33.5473C28.5949 33.3166 28.0305 33.0761 27.1472 33.0761C26.2106 33.0761 25.6209 33.3243 25.0521 33.5637C24.5606 33.7705 24.0848 33.9708 23.4142 33.9986C21.9359 34.0533 20.8061 32.5094 19.8395 31.1271C17.9073 28.3043 16.4028 23.1721 18.4198 19.6799C18.8855 18.8432 19.5599 18.1414 20.3774 17.6428C21.1949 17.1442 22.1277 16.8659 23.0847 16.835C23.9232 16.8177 24.7277 17.1409 25.433 17.4242C25.9725 17.6409 26.4539 17.8342 26.8481 17.8342C27.1947 17.8342 27.6627 17.6485 28.2081 17.432C29.0672 17.0911 30.1184 16.6738 31.1897 16.7863C32.0107 16.812 32.8144 17.0287 33.537 17.4194C34.2595 17.81 34.881 18.3637 35.3522 19.0366C34.6106 19.4925 33.9963 20.1286 33.5666 20.8857Z"
      fill="currentColor"
    />
    <path
      d="M53.6409 32.7889H49.0482L47.9453 36.0455H46L50.3501 23.9967H52.3712L56.7213 36.0455H54.7428L53.6409 32.7889ZM49.5238 31.2861H53.1643L51.3697 26.0008H51.3195L49.5238 31.2861Z"
      fill="currentColor"
    />
    <path
      d="M66.1162 31.6547C66.1162 34.3846 64.6551 36.1384 62.4502 36.1384C61.8916 36.1676 61.3362 36.039 60.8474 35.7672C60.3585 35.4954 59.9561 35.0914 59.6862 34.6015H59.6445V38.9526H57.8414V27.262H59.5868V28.7231H59.6199C59.9022 28.2356 60.3114 27.8337 60.804 27.5602C61.2965 27.2867 61.8539 27.1519 62.417 27.1701C64.6465 27.1701 66.1162 28.9325 66.1162 31.6547ZM64.2628 31.6547C64.2628 29.8762 63.3437 28.707 61.9414 28.707C60.5637 28.707 59.637 29.9009 59.637 31.6547C59.637 33.4247 60.5637 34.6101 61.9414 34.6101C63.3437 34.6101 64.2628 33.4494 64.2628 31.6547Z"
      fill="currentColor"
    />
    <path
      d="M75.7846 31.6547C75.7846 34.3846 74.3235 36.1384 72.1186 36.1384C71.5601 36.1676 71.0046 36.039 70.5158 35.7672C70.027 35.4954 69.6246 35.0914 69.3547 34.6015H69.3129V38.9526H67.5098V27.262H69.2551V28.7231H69.2883C69.5706 28.2355 69.9798 27.8337 70.4723 27.5602C70.9649 27.2867 71.5223 27.1519 72.0854 27.1701C74.315 27.1701 75.7846 28.9325 75.7846 31.6547ZM73.9312 31.6547C73.9312 29.8762 73.0121 28.707 71.6098 28.707C70.2321 28.707 69.3054 29.9009 69.3054 31.6547C69.3054 33.4247 70.2321 34.6101 71.6098 34.6101C73.0121 34.6101 73.9312 33.4493 73.9312 31.6547Z"
      fill="currentColor"
    />
    <path
      d="M82.1748 32.6885C82.3084 33.8833 83.4691 34.6678 85.0552 34.6678C86.5751 34.6678 87.6685 33.8833 87.6685 32.8059C87.6685 31.8707 87.009 31.3108 85.4475 30.927L83.886 30.5508C81.6735 30.0164 80.6464 28.9817 80.6464 27.3027C80.6464 25.2238 82.4581 23.7959 85.0306 23.7959C87.5766 23.7959 89.3219 25.2238 89.3807 27.3027H87.5605C87.4515 26.1003 86.4576 25.3745 85.005 25.3745C83.5524 25.3745 82.5585 26.1088 82.5585 27.1776C82.5585 28.0295 83.1933 28.5307 84.7463 28.9144L86.0738 29.2404C88.5459 29.825 89.573 30.818 89.573 32.5804C89.573 34.8346 87.7775 36.2464 84.9216 36.2464C82.2496 36.2464 80.4455 34.8677 80.3289 32.6884L82.1748 32.6885Z"
      fill="currentColor"
    />
    <path
      d="M93.4646 25.1833V27.2622H95.135V28.6901H93.4646V33.5329C93.4646 34.2853 93.799 34.6359 94.5334 34.6359C94.7317 34.6324 94.9297 34.6185 95.1265 34.5941V36.0135C94.7964 36.0752 94.4608 36.1032 94.125 36.0969C92.3465 36.0969 91.6529 35.4289 91.6529 33.7253V28.6901H90.3756V27.2622H91.6529V25.1833H93.4646Z"
      fill="currentColor"
    />
    <path
      d="M96.1024 31.6548C96.1024 28.8909 97.7302 27.1541 100.269 27.1541C102.816 27.1541 104.436 28.8908 104.436 31.6548C104.436 34.4263 102.824 36.1556 100.269 36.1556C97.7141 36.1556 96.1024 34.4263 96.1024 31.6548ZM102.599 31.6548C102.599 29.7588 101.73 28.6398 100.269 28.6398C98.8076 28.6398 97.9396 29.7673 97.9396 31.6548C97.9396 33.5584 98.8076 34.6689 100.269 34.6689C101.73 34.6689 102.599 33.5584 102.599 31.6548Z"
      fill="currentColor"
    />
    <path
      d="M105.923 27.2619H107.642V28.7571H107.684C107.8 28.2901 108.074 27.8774 108.459 27.5884C108.844 27.2994 109.316 27.1516 109.797 27.17C110.005 27.1693 110.212 27.1918 110.415 27.2373V28.9239C110.153 28.8437 109.879 28.8069 109.605 28.8149C109.343 28.8043 109.082 28.8505 108.839 28.9503C108.597 29.0501 108.379 29.2012 108.2 29.3932C108.022 29.5852 107.887 29.8136 107.805 30.0626C107.723 30.3117 107.696 30.5755 107.726 30.836V36.0464H105.923L105.923 27.2619Z"
      fill="currentColor"
    />
    <path
      d="M118.728 33.4665C118.486 35.0612 116.933 36.1556 114.946 36.1556C112.39 36.1556 110.804 34.4434 110.804 31.6965C110.804 28.9411 112.399 27.1541 114.87 27.1541C117.3 27.1541 118.829 28.8236 118.829 31.4871V32.1049H112.624V32.2139C112.596 32.5371 112.636 32.8628 112.743 33.1693C112.85 33.4757 113.021 33.7559 113.244 33.9913C113.468 34.2266 113.739 34.4117 114.04 34.5342C114.34 34.6568 114.663 34.714 114.988 34.7021C115.414 34.742 115.841 34.6433 116.207 34.4207C116.572 34.1981 116.856 33.8634 117.016 33.4665L118.728 33.4665ZM112.633 30.8447H117.025C117.041 30.554 116.997 30.2631 116.895 29.9902C116.794 29.7174 116.637 29.4685 116.435 29.2591C116.232 29.0498 115.989 28.8845 115.72 28.7737C115.45 28.6629 115.161 28.6089 114.87 28.6151C114.576 28.6134 114.285 28.6698 114.014 28.7811C113.742 28.8925 113.495 29.0565 113.287 29.2638C113.079 29.4711 112.914 29.7175 112.802 29.9888C112.69 30.2602 112.632 30.551 112.633 30.8447Z"
      fill="currentColor"
    />
    <path
      d="M130.137 17.121C129.609 17.121 129.129 17 128.696 16.758C128.263 16.5087 127.922 16.1713 127.673 15.746C127.424 15.3207 127.299 14.8477 127.299 14.327C127.299 13.8137 127.42 13.3443 127.662 12.919C127.904 12.4863 128.234 12.1453 128.652 11.896C129.07 11.6467 129.532 11.522 130.038 11.522C130.493 11.522 130.907 11.6247 131.281 11.83C131.655 12.0353 131.948 12.3213 132.161 12.688C132.374 13.0547 132.48 13.4727 132.48 13.942C132.48 14.1473 132.458 14.36 132.414 14.58H128.179C128.23 15.108 128.439 15.5333 128.806 15.856C129.173 16.1787 129.62 16.34 130.148 16.34C130.757 16.34 131.303 16.098 131.787 15.614L132.282 16.186C132.003 16.4793 131.681 16.7103 131.314 16.879C130.955 17.0403 130.562 17.121 130.137 17.121ZM131.611 13.832C131.604 13.3553 131.446 12.9813 131.138 12.71C130.837 12.4313 130.463 12.292 130.016 12.292C129.569 12.292 129.18 12.435 128.85 12.721C128.52 12.9997 128.307 13.37 128.212 13.832H131.611Z"
      fill="currentColor"
    />
    <path
      d="M121.454 8.75H122.301V12.248C122.484 12.0207 122.711 11.8447 122.983 11.72C123.261 11.588 123.569 11.522 123.907 11.522C124.523 11.522 125.007 11.7163 125.359 12.105C125.711 12.4937 125.887 13.0033 125.887 13.634V17H125.04V13.744C125.04 13.3113 124.919 12.963 124.677 12.699C124.442 12.435 124.119 12.303 123.709 12.303C123.298 12.303 122.961 12.4387 122.697 12.71C122.433 12.9813 122.301 13.326 122.301 13.744V17H121.454V8.75Z"
      fill="currentColor"
    />
    <path
      d="M119.299 17.121C118.742 17.121 118.302 16.978 117.979 16.692C117.656 16.406 117.495 15.9697 117.495 15.383V12.402H116.593V11.643H117.495V10.18H118.342V11.643H120.014V12.402H118.342V15.328C118.342 15.7093 118.437 15.977 118.628 16.131C118.826 16.2777 119.108 16.351 119.475 16.351C119.68 16.351 119.919 16.329 120.19 16.285V17C119.853 17.0807 119.556 17.121 119.299 17.121Z"
      fill="currentColor"
    />
    <path
      d="M107.951 11.643H108.743V12.215C108.941 11.995 109.179 11.8263 109.458 11.709C109.744 11.5843 110.055 11.522 110.393 11.522C111.016 11.522 111.504 11.7163 111.856 12.105C112.208 12.4937 112.384 13.0033 112.384 13.634V17H111.537V13.733C111.537 13.3077 111.416 12.963 111.174 12.699C110.939 12.435 110.616 12.303 110.206 12.303C109.795 12.303 109.458 12.4387 109.194 12.71C108.93 12.974 108.798 13.315 108.798 13.733V17H107.951V11.643Z"
      fill="currentColor"
    />
    <path
      d="M103.646 17.11C103.132 17.11 102.663 16.9853 102.238 16.736C101.812 16.4867 101.475 16.1493 101.226 15.724C100.976 15.2913 100.852 14.822 100.852 14.316C100.852 13.8027 100.976 13.3333 101.226 12.908C101.475 12.4753 101.812 12.138 102.238 11.896C102.67 11.6467 103.143 11.522 103.657 11.522C104.17 11.522 104.639 11.6467 105.065 11.896C105.497 12.1453 105.838 12.4827 106.088 12.908C106.337 13.3333 106.462 13.8027 106.462 14.316C106.462 14.822 106.337 15.2913 106.088 15.724C105.838 16.1493 105.497 16.4867 105.065 16.736C104.632 16.9853 104.159 17.11 103.646 17.11ZM101.721 14.327C101.721 14.701 101.805 15.042 101.974 15.35C102.142 15.6507 102.373 15.889 102.667 16.065C102.96 16.241 103.286 16.329 103.646 16.329C104.005 16.329 104.331 16.241 104.625 16.065C104.925 15.889 105.16 15.6507 105.329 15.35C105.505 15.042 105.593 14.701 105.593 14.327C105.593 13.9457 105.508 13.601 105.34 13.293C105.171 12.985 104.936 12.743 104.636 12.567C104.342 12.391 104.016 12.303 103.657 12.303C103.29 12.303 102.96 12.391 102.667 12.567C102.373 12.743 102.142 12.985 101.974 13.293C101.805 13.601 101.721 13.9457 101.721 14.327Z"
      fill="currentColor"
    />
    <path
      d="M93.4696 17.121C92.9709 17.121 92.5199 17 92.1166 16.758C91.7132 16.5087 91.3942 16.1713 91.1596 15.746C90.9322 15.3207 90.8186 14.8477 90.8186 14.327C90.8186 13.8063 90.9322 13.3333 91.1596 12.908C91.3942 12.4753 91.7132 12.138 92.1166 11.896C92.5199 11.6467 92.9709 11.522 93.4696 11.522C93.8729 11.522 94.2506 11.6027 94.6026 11.764C94.9546 11.9253 95.2369 12.149 95.4496 12.435V8.75H96.2966V17H95.4936V16.153C95.2882 16.4537 95.0022 16.692 94.6356 16.868C94.2762 17.0367 93.8876 17.121 93.4696 17.121ZM91.6876 14.316C91.6876 14.6973 91.7682 15.042 91.9296 15.35C92.0982 15.658 92.3256 15.9 92.6116 16.076C92.9049 16.252 93.2312 16.34 93.5906 16.34C93.9132 16.34 94.2176 16.2667 94.5036 16.12C94.7896 15.9733 95.0206 15.7497 95.1966 15.449C95.3726 15.1483 95.4606 14.7743 95.4606 14.327C95.4606 13.8797 95.3726 13.5057 95.1966 13.205C95.0206 12.897 94.7896 12.6697 94.5036 12.523C94.2176 12.3763 93.9132 12.303 93.5906 12.303C93.2312 12.303 92.9049 12.391 92.6116 12.567C92.3256 12.7357 92.0982 12.974 91.9296 13.282C91.7682 13.59 91.6876 13.9347 91.6876 14.316Z"
      fill="currentColor"
    />
    <path
      d="M86.811 17.121C86.261 17.121 85.81 16.9853 85.458 16.714C85.1134 16.4427 84.941 16.076 84.941 15.614C84.941 15.1593 85.117 14.7963 85.469 14.525C85.821 14.2537 86.2867 14.118 86.866 14.118H88.56V13.656C88.56 13.216 88.4427 12.8823 88.208 12.655C87.9807 12.4203 87.6654 12.303 87.262 12.303C86.9394 12.303 86.6534 12.3763 86.404 12.523C86.1547 12.6623 85.92 12.875 85.7 13.161L85.139 12.699C85.381 12.303 85.6927 12.0097 86.074 11.819C86.4554 11.621 86.8697 11.522 87.317 11.522C87.9477 11.522 88.4537 11.709 88.835 12.083C89.2164 12.457 89.407 12.996 89.407 13.7V17H88.615V16.307C88.417 16.571 88.164 16.7727 87.856 16.912C87.548 17.0513 87.1997 17.121 86.811 17.121ZM85.81 15.57C85.81 15.8413 85.9164 16.0503 86.129 16.197C86.3417 16.3437 86.6057 16.417 86.921 16.417C87.2144 16.417 87.4857 16.3547 87.735 16.23C87.9844 16.098 88.1824 15.9183 88.329 15.691C88.483 15.4637 88.56 15.2143 88.56 14.943V14.811H86.965C86.591 14.811 86.305 14.877 86.107 15.009C85.909 15.1337 85.81 15.3207 85.81 15.57Z"
      fill="currentColor"
    />
    <path
      d="M81.0872 17.11C80.5738 17.11 80.1045 16.9853 79.6792 16.736C79.2538 16.4867 78.9165 16.1493 78.6672 15.724C78.4178 15.2913 78.2932 14.822 78.2932 14.316C78.2932 13.8027 78.4178 13.3333 78.6672 12.908C78.9165 12.4753 79.2538 12.138 79.6792 11.896C80.1118 11.6467 80.5848 11.522 81.0982 11.522C81.6115 11.522 82.0808 11.6467 82.5062 11.896C82.9388 12.1453 83.2798 12.4827 83.5292 12.908C83.7785 13.3333 83.9032 13.8027 83.9032 14.316C83.9032 14.822 83.7785 15.2913 83.5292 15.724C83.2798 16.1493 82.9388 16.4867 82.5062 16.736C82.0735 16.9853 81.6005 17.11 81.0872 17.11ZM79.1622 14.327C79.1622 14.701 79.2465 15.042 79.4152 15.35C79.5838 15.6507 79.8148 15.889 80.1082 16.065C80.4015 16.241 80.7278 16.329 81.0872 16.329C81.4465 16.329 81.7728 16.241 82.0662 16.065C82.3668 15.889 82.6015 15.6507 82.7702 15.35C82.9462 15.042 83.0342 14.701 83.0342 14.327C83.0342 13.9457 82.9498 13.601 82.7812 13.293C82.6125 12.985 82.3778 12.743 82.0772 12.567C81.7838 12.391 81.4575 12.303 81.0982 12.303C80.7315 12.303 80.4015 12.391 80.1082 12.567C79.8148 12.743 79.5838 12.985 79.4152 13.293C79.2465 13.601 79.1622 13.9457 79.1622 14.327Z"
      fill="currentColor"
    />
    <path d="M75.9711 8.75H76.8181V17H75.9711V8.75Z" fill="currentColor" />
    <path
      d="M69.7407 11.643H70.5327V12.215C70.7307 11.995 70.969 11.8263 71.2477 11.709C71.5337 11.5843 71.8453 11.522 72.1827 11.522C72.806 11.522 73.2937 11.7163 73.6457 12.105C73.9977 12.4937 74.1737 13.0033 74.1737 13.634V17H73.3267V13.733C73.3267 13.3077 73.2057 12.963 72.9637 12.699C72.729 12.435 72.4063 12.303 71.9957 12.303C71.585 12.303 71.2477 12.4387 70.9837 12.71C70.7197 12.974 70.5877 13.315 70.5877 13.733V17H69.7407V11.643Z"
      fill="currentColor"
    />
    <path
      d="M59.7352 11.643H60.6372L62.1882 15.614L63.9702 11.522H64.2892L66.0932 15.581L67.6112 11.643H68.5132L66.2582 17.121H65.9502L64.1242 13.073L62.3092 17.121H62.0122L59.7352 11.643Z"
      fill="currentColor"
    />
    <path
      d="M56.2942 17.11C55.7809 17.11 55.3115 16.9853 54.8862 16.736C54.4609 16.4867 54.1235 16.1493 53.8742 15.724C53.6249 15.2913 53.5002 14.822 53.5002 14.316C53.5002 13.8027 53.6249 13.3333 53.8742 12.908C54.1235 12.4753 54.4609 12.138 54.8862 11.896C55.3189 11.6467 55.7919 11.522 56.3052 11.522C56.8185 11.522 57.2879 11.6467 57.7132 11.896C58.1459 12.1453 58.4869 12.4827 58.7362 12.908C58.9855 13.3333 59.1102 13.8027 59.1102 14.316C59.1102 14.822 58.9855 15.2913 58.7362 15.724C58.4869 16.1493 58.1459 16.4867 57.7132 16.736C57.2805 16.9853 56.8075 17.11 56.2942 17.11ZM54.3692 14.327C54.3692 14.701 54.4535 15.042 54.6222 15.35C54.7909 15.6507 55.0219 15.889 55.3152 16.065C55.6085 16.241 55.9349 16.329 56.2942 16.329C56.6535 16.329 56.9799 16.241 57.2732 16.065C57.5739 15.889 57.8085 15.6507 57.9772 15.35C58.1532 15.042 58.2412 14.701 58.2412 14.327C58.2412 13.9457 58.1569 13.601 57.9882 13.293C57.8195 12.985 57.5849 12.743 57.2842 12.567C56.9909 12.391 56.6645 12.303 56.3052 12.303C55.9385 12.303 55.6085 12.391 55.3152 12.567C55.0219 12.743 54.7909 12.985 54.6222 13.293C54.4535 13.601 54.3692 13.9457 54.3692 14.327Z"
      fill="currentColor"
    />
    <path
      d="M49.201 17.121C48.7023 17.121 48.2513 17 47.848 16.758C47.4447 16.5087 47.1257 16.1713 46.891 15.746C46.6637 15.3207 46.55 14.8477 46.55 14.327C46.55 13.8063 46.6637 13.3333 46.891 12.908C47.1257 12.4753 47.4447 12.138 47.848 11.896C48.2513 11.6467 48.7023 11.522 49.201 11.522C49.6043 11.522 49.982 11.6027 50.334 11.764C50.686 11.9253 50.9683 12.149 51.181 12.435V8.75H52.028V17H51.225V16.153C51.0197 16.4537 50.7337 16.692 50.367 16.868C50.0077 17.0367 49.619 17.121 49.201 17.121ZM47.419 14.316C47.419 14.6973 47.4997 15.042 47.661 15.35C47.8297 15.658 48.057 15.9 48.343 16.076C48.6363 16.252 48.9627 16.34 49.322 16.34C49.6447 16.34 49.949 16.2667 50.235 16.12C50.521 15.9733 50.752 15.7497 50.928 15.449C51.104 15.1483 51.192 14.7743 51.192 14.327C51.192 13.8797 51.104 13.5057 50.928 13.205C50.752 12.897 50.521 12.6697 50.235 12.523C49.949 12.3763 49.6447 12.303 49.322 12.303C48.9627 12.303 48.6363 12.391 48.343 12.567C48.057 12.7357 47.8297 12.974 47.661 13.282C47.4997 13.59 47.419 13.9347 47.419 14.316Z"
      fill="currentColor"
    />
  </svg>
</template>
