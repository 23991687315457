import { FastFilterMapper } from '../seo/fast-filter';
import type { ApiFilteredMedia } from './content-types/filtered-media';
import { MediaMapper } from './media';
import type { FilteredMedia } from './types/filtered-media';

export class FilteredMediaMapper {
  static map({ contents, filters, fast_filters, total_count, page_title }: ApiFilteredMedia): FilteredMedia {
    const _filters = filters
      ? {
          contentType: filters.content_type,
          genresSlugs: filters.genres_slugs,
          genresSlugsRu: filters.genres_slugs_ru,
          countries: filters.countries,
          countriesRu: filters.countries_ru,
          years: filters.years,
        }
      : undefined;

    return {
      contents: MediaMapper.mapMany(contents || []),
      filters: _filters,
      fastFilters: FastFilterMapper.mapMany(fast_filters || []),
      totalCount: total_count,
      pageTitle: page_title,
    };
  }
}
