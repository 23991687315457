<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.2 2C0.537258 2 0 2.50914 0 3.13719V8.82317C0 9.41964 0.484594 9.90887 1.10104 9.95655L1.02423 10.0293C0.789915 10.2514 0.789915 10.6114 1.02423 10.8335C1.25854 11.0555 1.63844 11.0555 1.87276 10.8335L2.72129 10.0293C2.74424 10.0076 2.76495 9.98451 2.78341 9.96036H9.3622C9.38065 9.98448 9.40133 10.0075 9.42426 10.0293L10.2728 10.8334C10.5071 11.0554 10.887 11.0554 11.1213 10.8334C11.3556 10.6113 11.3556 10.2513 11.1213 10.0293L11.0271 9.94002C11.5813 9.83941 12 9.37763 12 8.82317V3.13719C12 2.50914 11.4627 2 10.8 2H1.2ZM7.56928 6.47084C7.87697 6.28371 7.87697 5.8159 7.56928 5.62877L5.49236 4.36566C5.18466 4.17854 4.80005 4.41245 4.80005 4.7867V7.31291C4.80005 7.68716 5.18466 7.92107 5.49236 7.73394L7.56928 6.47084Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.4 3C1.6268 3 1 3.62228 1 4.3899V11.3394C1 12.0685 1.56536 12.6664 2.28455 12.7247L2.19493 12.8136C1.92157 13.085 1.92157 13.5251 2.19493 13.7965C2.4683 14.0678 2.91152 14.0678 3.18488 13.7965L4.17483 12.8136C4.20162 12.7871 4.22578 12.7588 4.24731 12.7293H11.9226C11.9441 12.7588 11.9682 12.787 11.995 12.8135L12.9849 13.7963C13.2583 14.0677 13.7015 14.0677 13.9749 13.7963C14.2482 13.525 14.2482 13.0849 13.9749 12.8135L13.865 12.7045C14.5115 12.5815 15 12.0171 15 11.3394V4.3899C15 3.62228 14.3732 3 13.6 3H2.4ZM9.73077 8.48118C10.0897 8.26732 10.0897 7.73268 9.73077 7.51882L7.30769 6.07527C6.94872 5.86141 6.5 6.12873 6.5 6.55645V9.44355C6.5 9.87127 6.94872 10.1386 7.30769 9.92473L9.73077 8.48118Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 5C2.89543 5 2 5.89543 2 7V17C2 18.049 2.80766 18.9094 3.83507 18.9933L3.70705 19.1213C3.31652 19.5118 3.31652 20.145 3.70705 20.5355C4.09757 20.9261 4.73074 20.9261 5.12126 20.5355L6.53548 19.1213C6.57374 19.0831 6.60825 19.0425 6.63902 19H17.6037C17.6344 19.0424 17.6689 19.083 17.7071 19.1212L19.1213 20.5354C19.5118 20.9259 20.145 20.9259 20.5355 20.5354C20.9261 20.1449 20.9261 19.5117 20.5355 19.1212L20.3786 18.9642C21.3021 18.7873 22 17.9751 22 17V7C22 5.89543 21.1046 5 20 5H4ZM14.8 12.6926C15.3333 12.3847 15.3333 11.6149 14.8 11.307L11.2 9.22849C10.6667 8.92057 10 9.30547 10 9.92131V14.0782C10 14.6941 10.6667 15.079 11.2 14.7711L14.8 12.6926Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.33342 6.6665C3.86066 6.6665 2.66675 7.86041 2.66675 9.33317V22.6665C2.66675 24.0652 3.74362 25.2124 5.11351 25.3242L4.94281 25.4949C4.42211 26.0156 4.42211 26.8598 4.94281 27.3805C5.46351 27.9012 6.30773 27.9012 6.82843 27.3805L8.71405 25.4949C8.76507 25.4439 8.81109 25.3898 8.85211 25.3332H23.4716C23.5126 25.3897 23.5586 25.4438 23.6096 25.4947L25.4952 27.3803C26.0159 27.901 26.8601 27.901 27.3808 27.3803C27.9015 26.8596 27.9015 26.0154 27.3808 25.4947L27.1715 25.2855C28.4029 25.0495 29.3334 23.9667 29.3334 22.6665V9.33317C29.3334 7.86041 28.1395 6.6665 26.6667 6.6665H5.33342ZM19.7333 16.9239C20.4444 16.5134 20.4444 15.487 19.7333 15.0764L14.9333 12.3051C14.2221 11.8946 13.3333 12.4078 13.3333 13.2289V18.7715C13.3333 19.5926 14.2221 20.1058 14.9333 19.6952L19.7333 16.9239Z"
          fill="currentColor"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 3.13719C0 2.50914 0.537258 2 1.2 2H10.8C11.4627 2 12 2.50914 12 3.13719V8.82317C12 9.37763 11.5813 9.83941 11.0271 9.94002L11.1213 10.0293C11.3556 10.2513 11.3556 10.6113 11.1213 10.8334C10.887 11.0554 10.5071 11.0554 10.2728 10.8334L9.42426 10.0293C9.40133 10.0075 9.38065 9.98448 9.3622 9.96036H2.78341C2.76495 9.98451 2.74424 10.0076 2.72129 10.0293L1.87276 10.8335C1.63844 11.0555 1.25854 11.0555 1.02423 10.8335C0.789915 10.6114 0.789915 10.2514 1.02423 10.0293L1.10104 9.95655C0.484594 9.90887 0 9.41964 0 8.82317V3.13719ZM1.2 3.13719V8.82317H10.8V3.13719H1.2Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 3.13719C0 2.50914 0.537258 2 1.2 2H10.8C11.4627 2 12 2.50914 12 3.13719V8.82317C12 9.37763 11.5813 9.83941 11.0271 9.94002L11.1213 10.0293C11.3556 10.2513 11.3556 10.6113 11.1213 10.8334C10.887 11.0554 10.5071 11.0554 10.2728 10.8334L9.42426 10.0293C9.40133 10.0075 9.38065 9.98448 9.3622 9.96036H2.78341C2.76495 9.98451 2.74424 10.0076 2.72129 10.0293L1.87276 10.8335C1.63844 11.0555 1.25854 11.0555 1.02423 10.8335C0.789915 10.6114 0.789915 10.2514 1.02423 10.0293L1.10104 9.95655C0.484594 9.90887 0 9.41964 0 8.82317V3.13719ZM1.2 3.13719V8.82317H10.8V3.13719H1.2Z"
          fill="currentColor"
        />
        <path
          d="M7.56928 5.62877C7.87697 5.8159 7.87697 6.28371 7.56928 6.47084L5.49236 7.73394C5.18466 7.92107 4.80005 7.68716 4.80005 7.31291L4.80005 4.7867C4.80005 4.41245 5.18466 4.17854 5.49236 4.36566L7.56928 5.62877Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 4.3899C1 3.62228 1.6268 3 2.4 3H13.6C14.3732 3 15 3.62228 15 4.3899V11.3394C15 12.0171 14.5115 12.5815 13.865 12.7045L13.9749 12.8135C14.2482 13.0849 14.2482 13.525 13.9749 13.7963C13.7015 14.0677 13.2583 14.0677 12.9849 13.7963L11.995 12.8135C11.9682 12.787 11.9441 12.7588 11.9226 12.7293H4.24731C4.22578 12.7588 4.20162 12.7871 4.17483 12.8136L3.18488 13.7965C2.91152 14.0678 2.4683 14.0678 2.19493 13.7965C1.92157 13.5251 1.92157 13.085 2.19493 12.8136L2.28455 12.7247C1.56536 12.6664 1 12.0685 1 11.3394V4.3899ZM2.4 4.3899V11.3394H13.6V4.3899H2.4Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 4.3899C1 3.62228 1.6268 3 2.4 3H13.6C14.3732 3 15 3.62228 15 4.3899V11.3394C15 12.0171 14.5115 12.5815 13.865 12.7045L13.9749 12.8135C14.2482 13.0849 14.2482 13.525 13.9749 13.7963C13.7015 14.0677 13.2583 14.0677 12.9849 13.7963L11.995 12.8135C11.9682 12.787 11.9441 12.7588 11.9226 12.7293H4.24731C4.22578 12.7588 4.20162 12.7871 4.17483 12.8136L3.18488 13.7965C2.91152 14.0678 2.4683 14.0678 2.19493 13.7965C1.92157 13.5251 1.92157 13.085 2.19493 12.8136L2.28455 12.7247C1.56536 12.6664 1 12.0685 1 11.3394V4.3899ZM2.4 4.3899V11.3394H13.6V4.3899H2.4Z"
          fill="currentColor"
        />
        <path
          d="M9.73077 7.51882C10.0897 7.73268 10.0897 8.26732 9.73077 8.48118L7.30769 9.92473C6.94872 10.1386 6.5 9.87127 6.5 9.44355L6.5 6.55645C6.5 6.12873 6.94872 5.86141 7.30769 6.07527L9.73077 7.51882Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 17.9751 21.3021 18.7873 20.3786 18.9642L20.5355 19.1212C20.9261 19.5117 20.9261 20.1449 20.5355 20.5354C20.145 20.9259 19.5118 20.9259 19.1213 20.5354L17.7071 19.1212C17.6689 19.083 17.6344 19.0424 17.6037 19H6.63902C6.60825 19.0425 6.57374 19.0831 6.53548 19.1213L5.12126 20.5355C4.73074 20.9261 4.09757 20.9261 3.70705 20.5355C3.31652 20.145 3.31652 19.5118 3.70705 19.1213L3.83507 18.9933C2.80766 18.9094 2 18.049 2 17V7ZM4 7V17H20V7H4Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 17.9751 21.3021 18.7873 20.3786 18.9642L20.5355 19.1212C20.9261 19.5117 20.9261 20.1449 20.5355 20.5354C20.145 20.9259 19.5118 20.9259 19.1213 20.5354L17.7071 19.1212C17.6689 19.083 17.6344 19.0424 17.6037 19H6.63902C6.60825 19.0425 6.57374 19.0831 6.53548 19.1213L5.12126 20.5355C4.73074 20.9261 4.09757 20.9261 3.70705 20.5355C3.31652 20.145 3.31652 19.5118 3.70705 19.1213L3.83507 18.9933C2.80766 18.9094 2 18.049 2 17V7ZM4 7V17H20V7H4Z"
          fill="currentColor"
        />
        <path
          d="M14.8 11.307C15.3333 11.6149 15.3333 12.3847 14.8 12.6926L11.2 14.7711C10.6667 15.079 10 14.6941 10 14.0782L10 9.92131C10 9.30547 10.6667 8.92057 11.2 9.22849L14.8 11.307Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.66675 9.33317C2.66675 7.86041 3.86066 6.6665 5.33341 6.6665H26.6667C28.1395 6.6665 29.3334 7.86041 29.3334 9.33317V22.6665C29.3334 23.9667 28.4029 25.0495 27.1715 25.2855L27.3808 25.4947C27.9015 26.0154 27.9015 26.8596 27.3808 27.3803C26.8601 27.901 26.0159 27.901 25.4952 27.3803L23.6096 25.4947C23.5586 25.4438 23.5126 25.3897 23.4716 25.3332H8.85211C8.81109 25.3898 8.76507 25.4439 8.71405 25.4949L6.82843 27.3806C6.30773 27.9012 5.46351 27.9012 4.94281 27.3806C4.42211 26.8599 4.42211 26.0156 4.94281 25.4949L5.11351 25.3242C3.74362 25.2124 2.66675 24.0652 2.66675 22.6665V9.33317ZM5.33341 9.33317V22.6665H26.6667V9.33317H5.33341Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.66675 9.33317C2.66675 7.86041 3.86066 6.6665 5.33341 6.6665H26.6667C28.1395 6.6665 29.3334 7.86041 29.3334 9.33317V22.6665C29.3334 23.9667 28.4029 25.0495 27.1715 25.2855L27.3808 25.4947C27.9015 26.0154 27.9015 26.8596 27.3808 27.3803C26.8601 27.901 26.0159 27.901 25.4952 27.3803L23.6096 25.4947C23.5586 25.4438 23.5126 25.3897 23.4716 25.3332H8.85211C8.81109 25.3898 8.76507 25.4439 8.71405 25.4949L6.82843 27.3806C6.30773 27.9012 5.46351 27.9012 4.94281 27.3806C4.42211 26.8599 4.42211 26.0156 4.94281 25.4949L5.11351 25.3242C3.74362 25.2124 2.66675 24.0652 2.66675 22.6665V9.33317ZM5.33341 9.33317V22.6665H26.6667V9.33317H5.33341Z"
          fill="currentColor"
        />
        <path
          d="M19.7333 15.0764C20.4444 15.487 20.4444 16.5134 19.7333 16.9239L14.9333 19.6952C14.2221 20.1058 13.3333 19.5926 13.3333 18.7715L13.3333 13.2289C13.3333 12.4078 14.2221 11.8946 14.9333 12.3051L19.7333 15.0764Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
