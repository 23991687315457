import { AppLanguage } from '../localization/language';
import { AppVariation } from './interfaces';
import useAmVariation from './use-am-variation';
import useRuVariation from './use-ru-variation';
import type { IAppStoreLink, ISocialMediaLink } from './variation-types';

export default function useVariationInfo() {
  const { ruSocialMediaLinks, ruStoreLinks, ruCompanyInformation } = useRuVariation();
  const { amStoreLinks, getAmCompanyInformation, amSocialMediaLinks } = useAmVariation();

  const getCompanyInformation = (variation: AppVariation, appLanguage: AppLanguage) => {
    if (variation === AppVariation.Ru) {
      return ruCompanyInformation;
    }

    if (variation === AppVariation.Am) {
      return getAmCompanyInformation(appLanguage);
    }

    return ruCompanyInformation;
  };

  const getSocialMediaLinks = (appVariation: AppVariation): ISocialMediaLink[] => {
    if (appVariation === AppVariation.Am) {
      return amSocialMediaLinks;
    }

    return ruSocialMediaLinks;
  };

  const getAppStoreLinks = (appVariation: AppVariation): IAppStoreLink[] => {
    if (appVariation === AppVariation.Ru) {
      return ruStoreLinks;
    }
    return amStoreLinks;
  };

  return {
    getAppStoreLinks,
    getCompanyInformation,
    getSocialMediaLinks,
  };
}
