import 'core-js/modules/es.array.at';
import 'core-js/modules/es.string.at-alternative';

import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import useLogger from '@package/logger/src/use-logger';
import { ParentalControlStep } from '@package/sdk/src/api';
import { isDefined } from '@package/sdk/src/core';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import { isClient } from '@vueuse/core';
import crypto from 'crypto';
import { reloadNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { getClientIp } from 'request-ip';
import { v4 } from 'uuid';
import { computed, watch } from 'vue';

import { useAdmitadUserId } from '@/code/admitad/use-admitad-user-id';
import useAuthActions from '@/code/auth/use-auth-actions';
import { useAppTheme } from '@/code/layout/use-app-theme';
import useParentalControl from '@/code/profile/use-parental-control';
import useProfile from '@/code/profile/use-profile';
import { usePageMeta } from '@/code/seo/use-page-meta';
import useTvPromoPopupActions from '@/code/tv/use-tv-promo-popup-actions';
import useTvCodeConnection from '@/code/tv-code/use-tv-code-connection';
import { useVpnNoticeApi } from '@/code/vpn/use-vpn-notice-api';
import { executeWithSafeClientContext } from '@/platform/base/function';
import { CookieName, cookies, maxAge } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import useEnvironment from '@/platform/environment/use-environment';
import { useHostname } from '@/platform/network/use-hostname';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import useHostVariation from '@/platform/variation/use-host-variation';
import { useCatalogStore } from '@/stores/use-catalog-store';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import useParentalControlStore from '@/stores/use-parental-control-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';
import { type AppConfig, useSystemStore } from '@/stores/use-system-store';

if (process.server) {
  try {
    Object.defineProperty(globalThis, 'crypto', {
      value: {
        getRandomValues: (arr: any) => crypto.randomBytes(arr.length),
      },
    });
  } catch (e) {
    console.error(e);
  }
}

declare const window: Window &
  typeof globalThis & {
    startTime?: number;
    accessToken?: string;
  };

export default defineNuxtPlugin(async (app) => {
  const { appVariation } = useHostVariation();
  const { appVersion, isDev } = useEnvironment();

  const { $http, $emitter, $contentCacheManager } = useNuxtApp();

  console.info('%c INFO', 'color: #33f', `APP_VARIATION - ${appVariation}`);

  const route = useRoute();
  const offersStore = useOffersStore();
  const featuresStore = useFeaturesStore();
  const sessionStore = useSessionStore();
  const catalogStore = useCatalogStore();
  const promocodeStore = usePromocodeStore();
  const localStorage = useLocalStorage();
  const hostName = useHostname();

  const { sendAdmitadEvent } = useAdmitadUserId();
  const tvPromoPopupActions = useTvPromoPopupActions();
  const { restoreSavedPromocode } = useAuthActions();
  const { setupDefaultSeoMetadata } = usePageMeta();
  const { checkVpnStatus } = useVpnNoticeApi();
  const { isVpnDetected, config } = storeToRefs(useSystemStore());

  const logger = useLogger('boot');

  const { _offers } = storeToRefs(offersStore);
  const { isAuth } = storeToRefs(sessionStore);
  const { promocodeCheckInfo } = storeToRefs(promocodeStore);

  const accessToken = route.query[RouteQuery.AccessToken] as string;
  const { initializeTheme } = useAppTheme();
  const visitorIdCookie = useAppCookie(CookieName.VisitorId, {
    maxAge: cookies.visitorId.maxAge,
    path: '/',
    default: () => v4(),
  });

  const createVisitorId = () => {
    if (!visitorIdCookie.value) {
      visitorIdCookie.value = v4();
    }
  };

  const createClientTimezoneCookie = () => {
    if (useAppCookie(CookieName.ClientTimezone).value) {
      return;
    }

    const zone = Math.abs(new Date().getTimezoneOffset()) / 60;
    const clientTimezone = useAppCookie(CookieName.ClientTimezone, { maxAge: cookies.clientTimezone.maxAge });

    clientTimezone.value = zone;
  };

  initializeTheme();
  setupDefaultSeoMetadata();
  createVisitorId();
  createClientTimezoneCookie();

  const resolveSmartTvCode = () => {
    const tvCodeQuery = route.query[RouteQuery.TvSmartCode] as string;

    if (!tvCodeQuery) {
      return;
    }

    sessionStore.setSavedSmartTvCode(tvCodeQuery);
  };

  const loadSessionFromQueryAccessToken = async (token: string) => {
    try {
      await sessionStore.loginUserWithAccessToken(token);
    } catch (error) {
      await sessionStore.clearSession();

      throw error;
    }
  };

  const fetchStaticInformation = async () => {
    const isCountriesLoaded = catalogStore.countries?.length > 0;
    const isGenresLoaded = catalogStore.genres?.length > 0;
    const isFeaturesLoaded = featuresStore.features?.length > 0;
    const isPromocodeCheckInfoLoaded = isDefined(promocodeCheckInfo.value);
    const isYearsLoaded = catalogStore.years?.length > 0;

    await Promise.all([
      isCountriesLoaded ? Promise.resolve(undefined) : catalogStore.fetchCountries(),
      isGenresLoaded ? Promise.resolve(undefined) : catalogStore.fetchGenres(),
      isFeaturesLoaded ? Promise.resolve(undefined) : featuresStore.fetchFeatures(),
      isPromocodeCheckInfoLoaded ? Promise.resolve(undefined) : restoreSavedPromocode(),
      isYearsLoaded ? Promise.resolve(undefined) : catalogStore.fetchYears(),
    ]);
  };

  const bootSession = async () => {
    resolveSmartTvCode();

    const isMustLoadSessionFromToken = process.server && Boolean(accessToken);
    const isOffersLoaded = Boolean(_offers.value?.length);

    const loadSessionByTokenPromise = isMustLoadSessionFromToken
      ? loadSessionFromQueryAccessToken(accessToken)
      : Promise.resolve(undefined);

    const loadNormalSessionPromise = !isMustLoadSessionFromToken
      ? sessionStore.loadSession({ forceLoadUser: true })
      : Promise.resolve(undefined);

    const offersLoadPromise = !isOffersLoaded ? offersStore.loadOffer() : Promise.resolve(undefined);

    try {
      await Promise.all([loadSessionByTokenPromise, loadNormalSessionPromise, offersLoadPromise]);
    } catch (error) {
      logger.error(error);
    }
  };

  const checkCacheVersion = async () => {
    const currentCacheVersion = await $http.cache.readEntry(ConstantsConfigInstanceWeb.getProperty('cacheVersion'));

    try {
      const configJson = await $fetch<AppConfig>(`${hostName}/config.json`);

      config.value = configJson;

      if (config.value.clientCacheVersion !== currentCacheVersion?.value) {
        $http.cache.clear();
        await $http.cache.addEntry(
          ConstantsConfigInstanceWeb.getProperty('cacheVersion'),
          config.value.clientCacheVersion,
        );
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const detectVpn = async () => {
    try {
      const { possibleVpn } = await checkVpnStatus();

      if (isVpnDetected.value !== possibleVpn && isDefined(isVpnDetected.value)) {
        $http.cache.clear();
        reloadNuxtApp();
      }
      isVpnDetected.value = possibleVpn;
    } catch (err) {
      logger.error(err);
    }
  };

  const startTickVpnIntervalUpdate = () => {
    detectVpn();
    window.setInterval(detectVpn, ConstantsConfigInstanceWeb.getProperty('updateVpnStatusTimeoutMs'));
  };

  const registerWatchers = () => {
    console.info(
      '%c Viju website version: ' + '%c '.concat(appVersion || '', ' '),
      'color: #fff; background: #0A1E1F',
      'color: #fff; background: #cc6666',
    );
  };

  // Загружаем основную сессию
  await bootSession();

  // Грузим всякие словари, фильтры, фича тоггла
  await fetchStaticInformation();

  executeWithSafeClientContext(() => {
    const onChunkLoadError = (event: Event) => {
      event.preventDefault();

      logger.error(event);

      /**
       * Работает не очень, надо придумать другие пути
       */
      // window.location.reload(); // for example, refresh the page
    };

    const registerModalChecking = () => {
      const route = useRoute();
      const layoutStore = useLayoutStore();
      const parentalControlStore = useParentalControlStore();
      const { shouldPassParentalControlPrompt, parentalControlModalState: modalState } = useProfile();
      const { isAuth, currentDeviceParentalControlStatus, isAccountParentalControlSettled } =
        storeToRefs(useSessionStore());

      const { resolveParentalControlStep } = useParentalControl();

      const { isTvCodePage } = useTvCodeConnection();

      const isThankYouPage = computed(() => route.name === AppRoute.ThankYou);

      watch(
        [isThankYouPage, isTvCodePage],
        (isThankYou, isTvCode) => {
          if (isThankYou || isTvCode) {
            if (layoutStore.currentModalName === 'ParentalControlModal') {
              layoutStore.setCurrentModalName(null);
            }
          }
        },
        { immediate: true },
      );

      watch(
        [isAuth, isThankYouPage, shouldPassParentalControlPrompt],
        async ([auth, isThankYou]) => {
          await nextTick();

          if (!auth || isThankYou) {
            return;
          }

          const step = resolveParentalControlStep({
            shouldPassParentalControlPrompt: shouldPassParentalControlPrompt.value,
            currentDeviceParentalControlStatus: currentDeviceParentalControlStatus.value,
            isAccountParentalControlSettled: isAccountParentalControlSettled.value,
            modalState: modalState.value,
          });

          parentalControlStore.setModalStep(step);

          if (step !== ParentalControlStep.Hidden && !isTvCodePage.value) {
            layoutStore.setCurrentModalName('ParentalControlModal', { hasCloseIcon: false });
          }
        },
        { immediate: true },
      );
    };

    const registerEventHandlers = () => {
      $emitter.on('user.login.complete', () => $contentCacheManager.clear());
      $emitter.on('user.logout.complete', () => $contentCacheManager.clear());
      $emitter.on('user.profile.updated', () => $contentCacheManager.clear());

      $emitter.on('offer.loadRequest', () => offersStore.loadOffer());
    };

    window.addEventListener('vite:preloadError', onChunkLoadError);

    const queryPartnerUserId = route.query[RouteQuery.AdmitadUid] as string;
    const shouldShowTvPromoPopup = route.query[RouteQuery.TvPopupPromo] === '1';
    // Только для неавторизованнхы пророводим всю эту логику с ТВ попапом
    if (shouldShowTvPromoPopup) {
      tvPromoPopupActions.addTvPromoPopupFlag();
    }

    if (queryPartnerUserId) {
      localStorage.setValue(LocalStorageKey.AdmitadUid, {
        value: queryPartnerUserId,
        expires: maxAge.NINETY_DAYS,
      });
    }

    if (isAuth.value) {
      sendAdmitadEvent();
    }

    window.startTime = window.performance.now();
    logger.info(`startLoadMainWorkbench ${window.startTime.toFixed(2)} ms.`);

    registerModalChecking();
    registerEventHandlers();
  });

  if (process.server && app.ssrContext?.event) {
    sessionStore._userIp = getClientIp(app.ssrContext.event.node.req) || '';
  }

  if (isClient) {
    checkCacheVersion();

    if (!isDev) {
      startTickVpnIntervalUpdate();
    }

    registerWatchers();
  }
});
