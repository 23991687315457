<template>
  <div :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'enterPinCodeModal')">
    <modal-title :title="$t('parentalControl.modal.enterPinCode')" />
    <modal-description :description="$t('parentalControl.modal.forAdultProfileAccess')" />

    <pin-code-input
      :model-value="pinCode"
      autofocus
      :class="$style.pinCodeInput"
      :is-error="error !== null"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'enterPinCodeInput')"
      @update:model-value="updatePinCode"
      @submit="onCheckSubmit"
    />

    <div v-if="error !== null" :class="$style.pinCodeError">
      <template v-if="error === PinCodeError.Wrong">
        {{ $t('parentalControl.modal.wrongPinCode') }}
      </template>
      <template v-else>{{ $t('parentalControl.modal.unexpectedError') }}</template>
    </div>

    <div :class="$style.profileLostMessage">
      {{ $t('parentalControl.modal.lostControl') }}
      <a :class="$style.email" :href="'mailto:' + company.supportEmail">{{ company.supportEmail }}</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

import useParentalControlApi from '@/code/profile/use-parental-control-api';
import useHostVariation from '@/platform/variation/use-host-variation';
import useVariationInfo from '@/platform/variation/use-variation-info';
import useParentalControlStore from '@/stores/use-parental-control-store';

import ModalDescription from '../modals/ModalDescription.vue';
import ModalTitle from '../modals/ModalTitle.vue';
import PinCodeInput from './PinCodeInput.vue';

enum PinCodeError {
  Wrong,
  Unknown,
}

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'success'): void;
  (e: 'unsuccessful'): void;
}>();

withDefaults(
  defineProps<{
    cancelType?: 'cancel' | 'logout';
  }>(),
  {
    cancelType: 'logout',
  },
);

const { $analyticSender } = useNuxtApp();
const { onAutoParentalCodeEnterDisplayed } = useParentalControlAnalytics($analyticSender);
const { appVariation, appLanguage } = useHostVariation();
const { getCompanyInformation } = useVariationInfo();

const company = getCompanyInformation(appVariation, appLanguage.value);

const parentalControlStore = useParentalControlStore();
const { pinCode } = storeToRefs(useParentalControlStore());
const error = ref<PinCodeError | null>(null);

parentalControlStore.setPinCode('');

const isPinCodeRequestLoading = ref(false);

const updatePinCode = (value: string) => {
  pinCode.value = value;
};

watch(pinCode, () => {
  error.value = null;
});

const parentalApi = useParentalControlApi();
const logger = useLogger('EnterPinCodeModalPrompt.vue');

onMounted(() => {
  onAutoParentalCodeEnterDisplayed();
});

const onCheckSubmit = async () => {
  isPinCodeRequestLoading.value = true;

  try {
    const isSuccess = await parentalApi.checkPinCode(pinCode.value);

    if (!isSuccess) {
      error.value = PinCodeError.Wrong;
      emit('unsuccessful');
      return;
    }

    emit('success');
  } catch (err) {
    logger.error(err);
    error.value = PinCodeError.Unknown;
    return;
  } finally {
    isPinCodeRequestLoading.value = false;
  }
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.pinCodeInput {
  margin-top: var(--g-spacing-24);
  margin-bottom: var(--g-spacing-24);
}

.pinCodeError {
  margin-top: var(--g-spacing-8);
  color: var(--color-text-negative);
}

.profileLostMessage {
  margin-top: var(--g-spacing-12);
  max-width: 400px;
  color: var(--color-text-tertiary);

  @include fonts.WebBody-3;
}

.email {
  color: var(--color-text-link);
  transition: color 0.1s ease;

  &:hover {
    color: var(--color-states-link-hover);
  }
}
</style>
