<template>
  <aside
    :class="{
      [$style.menuMobile]: true,
      [$style.menuMobileOpened]: isMenuOpen,
    }"
  >
    <app-slot-button :class="$style.closeMenuButton" tabindex="-1" @click="toggleBurgerMenu">
      <icon-close size="small" :class="$style.iconClose" />
    </app-slot-button>
    <nav
      :aria-label="$t('header.nav.mainMenu')"
      itemtype="https://www.schema.org/SiteNavigationElement"
      itemscope="itemscope"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'navBlock')"
    >
      <ul role="list" :class="$style.navList">
        <li v-for="link in navItems" :key="link.name">
          <app-link
            :to="link.target"
            tabindex="-1"
            :aria-label="link.ariaLabel"
            :class="{
              [$style.link]: true,
              [$style.linkActive]: isLinkActive(link),
            }"
            @click="onAppHeaderMenuLinkClick(link)"
          >
            <icon-viju v-if="link.name === AppRoute.Index" size="small" :class="$style.linkIcon" />
            <icon-my-channel v-if="link.name === AppRoute.MyChannel" size="small" :class="$style.linkIcon" />
            <icon-movie v-if="link.name === AppRoute.Movies" size="small" :class="$style.linkIcon" />
            <icon-series v-if="link.name === AppRoute.Serials" size="small" :class="$style.linkIcon" />
            <icon-tv v-if="link.name === AppRoute.TV" size="small" :class="$style.linkIcon" />
            <icon-bookmark
              v-if="link.name === AppRoute.MyCollectionKinom"
              size="small"
              :filled="true"
              :class="$style.linkIcon"
            />
            <span :class="$style.lineText">{{ link.description }}</span>
          </app-link>
        </li>
        <li v-if="isHeaderLanguagePickerShown">
          <app-slot-button tabindex="-1" :class="$style.link" @click="showLanguageMenu()">
            <icon-language size="small" :filled="true" :class="$style.linkIcon" />
            <span :class="$style.lineText">{{ $t('lk.language.title') }}</span>
          </app-slot-button>
        </li>
      </ul>
    </nav>
    <div :class="$style.buttonsBlock">
      <app-header-action-button
        v-if="isActionButtonShown"
        tabindex="-1"
        :class="$style.actionButton"
        @click="toggleBurgerMenu"
      />
      <app-header-connect-tv-button
        v-if="isConnectTvHeaderButtonShown"
        tabindex="-1"
        :class="{
          [$style.connectTvButton]: true,
          [$style.connectTvButtonWithText]: !isActionButtonShown,
        }"
        @click="toggleBurgerMenu"
      />
    </div>
    <app-header-user-block tabindex="-1" :class="$style.userBlockMobile" :is-auth="isAuth" @click="toggleBurgerMenu" />
  </aside>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';

import { useConnectTvHeaderButton } from '@/code/connect-tv/use-connect-tv';
import { usePromocodeActivationButton } from '@/code/promocode/use-promocode-activation-button';
import AppHeaderActionButton from '@/components/app-header/AppHeaderActionButton.vue';
import { AppRoute } from '@/platform/router/routes';
import { useSessionStore } from '@/stores/use-session-store';

import IconBookmark from '../icons/IconBookmark.vue';
import IconClose from '../icons/IconClose.vue';
import IconLanguage from '../icons/IconLanguage.vue';
import IconMovie from '../icons/IconMovie.vue';
import IconMyChannel from '../icons/IconMyChannel.vue';
import IconSeries from '../icons/IconSeries.vue';
import IconTv from '../icons/IconTv.vue';
import IconViju from '../icons/IconViju.vue';
import AppLink from '../ui/AppLink.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';
import AppHeaderConnectTvButton from './AppHeaderConnectTvButton.vue';
import AppHeaderUserBlock from './AppHeaderUserBlock.vue';
import type { AppHeaderItem } from './use-app-header-variables';
import useAppHeaderVariables from './use-app-header-variables';

const { isAuth } = storeToRefs(useSessionStore());
const { navItems, onAppHeaderLinkClick, isHeaderLanguagePickerShown, isLinkActive } = useAppHeaderVariables();
const { isActionButtonShown } = usePromocodeActivationButton();
const { isConnectTvHeaderButtonShown } = useConnectTvHeaderButton();

defineProps<{
  isMenuOpen: boolean;
}>();

const onAppHeaderMenuLinkClick = (link: AppHeaderItem) => {
  onAppHeaderLinkClick(link);
  toggleBurgerMenu();
};

const emit = defineEmits<{
  (e: 'show-language-menu'): void;
  (e: 'toggle-burger-menu'): void;
}>();

const showLanguageMenu = () => {
  emit('show-language-menu');
};

const toggleBurgerMenu = () => {
  emit('toggle-burger-menu');
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.menuMobile {
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--z-index-burger-menu);
  display: flex;
  flex-direction: column;
  padding: var(--g-spacing-56) var(--g-spacing-24) var(--g-spacing-24);
  width: 258px;
  height: var(--app-height);
  border-radius: var(--g-border-round-24) 0 0 var(--g-border-round-24);
  background-color: var(--color-bg-primary);
  overflow: auto;
  box-shadow: -24px 0px var(--g-spacing-24) 0px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menuMobileOpened {
  transform: translateX(0%);
}

.closeMenuButton {
  position: absolute;
  top: var(--g-spacing-10);
  right: var(--g-spacing-16);
  width: 36px;
  height: 36px;

  &:hover {
    .iconClose {
      color: var(--color-icon-primary);
    }
  }
}

.iconClose {
  color: var(--color-icon-additional);
}

.navList {
  display: grid;
  grid-auto-flow: row;
  gap: var(--g-spacing-16);
  margin-bottom: var(--g-spacing-16);
}

.link {
  display: flex;
  align-items: center;
  padding: var(--g-spacing-6) 0;
  color: var(--color-text-additional);
  white-space: nowrap;

  @include fonts.WebBody-1;

  &:hover {
    color: var(--color-text-primary);
  }
}

.lineText {
  color: inherit;
  transition: color 0.3s ease-in-out;
}

.linkMobile {
  padding: 0;

  @include fonts.WebHeadline-1;
}

.linkActive,
.linkActive:hover {
  color: var(--color-text-link);
}

.linkIcon {
  margin-right: var(--g-spacing-16);
}

.langButtonMobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.langButtonMobileTitle {
  display: flex;
  color: var(--color-text-primary);
}

.langButtonMobileIcon {
  margin: 0 0 0 var(--g-spacing-16);
  color: var(--color-icon-secondary);
}

.buttonsBlock {
  display: flex;
  margin-top: auto;
}

.actionButton {
  margin-bottom: var(--g-spacing-16);
  width: 100%;
  height: 40px;
}

.connectTvButton {
  margin-left: var(--g-spacing-16);
  height: 40px;
}

.connectTvButtonWithText {
  margin-left: 0;
  margin-bottom: var(--g-spacing-16);
  width: 100%;
}

.userBlockMobile {
  width: 100%;
}

@media screen and (min-width: 801px) {
  .menuMobile {
    transform: translateX(100%);
  }
}
</style>
