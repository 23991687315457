import type { PaymentOptions } from './payments-types/cloudpayments';
import type { ApiQrSignature } from './payments-types/payment-qr-signature';

export class PaymentQrSignatureMapper {
  static map({
    invoice_id,
    public_id,
    user_id,
    payment_widget_title,
    amount,
    currency,
    email,
    transaction_type,
  }: ApiQrSignature): PaymentOptions {
    return {
      invoiceId: invoice_id,
      publicId: public_id,
      accountId: user_id,
      description: payment_widget_title,
      amount: Number(amount),
      currency,
      email,
      auth: transaction_type === 'auth',
    };
  }
}
