<template>
  <svg width="152" height="48" viewBox="0 0 152 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2619_13797)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.2076 11.2891C31.3085 12.6076 30.8916 13.9135 30.0452 14.9295C29.6399 15.4336 29.1255 15.8391 28.5408 16.1156C27.9561 16.392 27.3163 16.5322 26.6695 16.5256C26.6282 15.8915 26.7123 15.2556 26.917 14.654C27.1217 14.0525 27.4429 13.4972 27.8623 13.0199C28.7201 12.0429 29.9145 11.4249 31.2076 11.2891ZM33.5666 20.8857C33.1369 21.6429 32.9057 22.4964 32.8945 23.3668C32.8955 24.346 33.1848 25.3032 33.7262 26.1191C34.2676 26.935 35.0372 27.5734 35.939 27.9548C35.5844 29.1067 35.05 30.1952 34.3554 31.1801C33.4226 32.5754 32.4446 33.9386 30.8924 33.9638C30.1541 33.9808 29.6558 33.7685 29.1365 33.5473C28.5949 33.3166 28.0305 33.0761 27.1472 33.0761C26.2106 33.0761 25.6209 33.3243 25.0521 33.5637C24.5606 33.7705 24.0848 33.9708 23.4142 33.9986C21.9359 34.0533 20.8061 32.5094 19.8395 31.1271C17.9073 28.3043 16.4028 23.1721 18.4198 19.6799C18.8855 18.8432 19.5599 18.1414 20.3774 17.6428C21.1949 17.1442 22.1277 16.8659 23.0847 16.835C23.9232 16.8177 24.7277 17.1409 25.433 17.4242C25.9725 17.6409 26.4539 17.8342 26.8481 17.8342C27.1947 17.8342 27.6627 17.6485 28.2081 17.432C29.0672 17.0911 30.1184 16.6738 31.1897 16.7863C32.0107 16.812 32.8144 17.0287 33.537 17.4194C34.2595 17.81 34.881 18.3637 35.3522 19.0366C34.6106 19.4925 33.9963 20.1286 33.5666 20.8857Z"
        fill="currentColor"
      />
      <path
        d="M53.6409 32.7889H49.0482L47.9453 36.0455H46L50.3501 23.9967H52.3712L56.7213 36.0455H54.7428L53.6409 32.7889ZM49.5238 31.2861H53.1643L51.3697 26.0008H51.3195L49.5238 31.2861Z"
        fill="currentColor"
      />
      <path
        d="M66.1162 31.6547C66.1162 34.3846 64.6551 36.1384 62.4502 36.1384C61.8916 36.1676 61.3362 36.039 60.8474 35.7672C60.3585 35.4954 59.9561 35.0914 59.6862 34.6015H59.6445V38.9526H57.8414V27.262H59.5868V28.7231H59.6199C59.9022 28.2356 60.3114 27.8337 60.804 27.5602C61.2965 27.2867 61.8539 27.1519 62.417 27.1701C64.6465 27.1701 66.1162 28.9325 66.1162 31.6547ZM64.2628 31.6547C64.2628 29.8762 63.3437 28.707 61.9414 28.707C60.5637 28.707 59.637 29.9009 59.637 31.6547C59.637 33.4247 60.5637 34.6101 61.9414 34.6101C63.3437 34.6101 64.2628 33.4494 64.2628 31.6547Z"
        fill="currentColor"
      />
      <path
        d="M75.7846 31.6547C75.7846 34.3846 74.3235 36.1384 72.1186 36.1384C71.5601 36.1676 71.0046 36.039 70.5158 35.7672C70.027 35.4954 69.6246 35.0914 69.3547 34.6015H69.3129V38.9526H67.5098V27.262H69.2551V28.7231H69.2883C69.5706 28.2355 69.9798 27.8337 70.4723 27.5602C70.9649 27.2867 71.5223 27.1519 72.0854 27.1701C74.315 27.1701 75.7846 28.9325 75.7846 31.6547ZM73.9312 31.6547C73.9312 29.8762 73.0121 28.707 71.6098 28.707C70.2321 28.707 69.3054 29.9009 69.3054 31.6547C69.3054 33.4247 70.2321 34.6101 71.6098 34.6101C73.0121 34.6101 73.9312 33.4493 73.9312 31.6547Z"
        fill="currentColor"
      />
      <path
        d="M82.1748 32.6885C82.3084 33.8833 83.4691 34.6678 85.0552 34.6678C86.5751 34.6678 87.6685 33.8833 87.6685 32.8059C87.6685 31.8707 87.009 31.3108 85.4475 30.927L83.886 30.5508C81.6735 30.0164 80.6464 28.9817 80.6464 27.3027C80.6464 25.2238 82.4581 23.7959 85.0306 23.7959C87.5766 23.7959 89.3219 25.2238 89.3807 27.3027H87.5605C87.4515 26.1003 86.4576 25.3745 85.005 25.3745C83.5524 25.3745 82.5585 26.1088 82.5585 27.1776C82.5585 28.0295 83.1933 28.5307 84.7463 28.9144L86.0738 29.2404C88.5459 29.825 89.573 30.818 89.573 32.5804C89.573 34.8346 87.7775 36.2464 84.9216 36.2464C82.2496 36.2464 80.4455 34.8677 80.3289 32.6884L82.1748 32.6885Z"
        fill="currentColor"
      />
      <path
        d="M93.4646 25.1833V27.2622H95.135V28.6901H93.4646V33.5329C93.4646 34.2853 93.799 34.6359 94.5334 34.6359C94.7317 34.6324 94.9297 34.6185 95.1265 34.5941V36.0135C94.7964 36.0752 94.4608 36.1032 94.125 36.0969C92.3465 36.0969 91.6529 35.4289 91.6529 33.7253V28.6901H90.3756V27.2622H91.6529V25.1833H93.4646Z"
        fill="currentColor"
      />
      <path
        d="M96.1024 31.6548C96.1024 28.8909 97.7302 27.1541 100.269 27.1541C102.816 27.1541 104.436 28.8908 104.436 31.6548C104.436 34.4263 102.824 36.1556 100.269 36.1556C97.7141 36.1556 96.1024 34.4263 96.1024 31.6548ZM102.599 31.6548C102.599 29.7588 101.73 28.6398 100.269 28.6398C98.8076 28.6398 97.9396 29.7673 97.9396 31.6548C97.9396 33.5584 98.8076 34.6689 100.269 34.6689C101.73 34.6689 102.599 33.5584 102.599 31.6548Z"
        fill="currentColor"
      />
      <path
        d="M105.923 27.2619H107.642V28.7571H107.684C107.8 28.2901 108.074 27.8774 108.459 27.5884C108.844 27.2994 109.316 27.1516 109.797 27.17C110.005 27.1693 110.212 27.1918 110.415 27.2373V28.9239C110.153 28.8437 109.879 28.8069 109.605 28.8149C109.343 28.8043 109.082 28.8505 108.839 28.9503C108.597 29.0501 108.379 29.2012 108.2 29.3932C108.022 29.5852 107.887 29.8136 107.805 30.0626C107.723 30.3117 107.696 30.5755 107.726 30.836V36.0464H105.923L105.923 27.2619Z"
        fill="currentColor"
      />
      <path
        d="M118.728 33.4665C118.486 35.0612 116.933 36.1556 114.946 36.1556C112.39 36.1556 110.804 34.4434 110.804 31.6965C110.804 28.9411 112.399 27.1541 114.87 27.1541C117.3 27.1541 118.829 28.8236 118.829 31.4871V32.1049H112.624V32.2139C112.596 32.5371 112.636 32.8628 112.743 33.1693C112.85 33.4757 113.021 33.7559 113.244 33.9913C113.468 34.2266 113.739 34.4117 114.04 34.5342C114.34 34.6568 114.663 34.714 114.988 34.7021C115.414 34.742 115.841 34.6433 116.207 34.4207C116.572 34.1981 116.856 33.8634 117.016 33.4665L118.728 33.4665ZM112.633 30.8447H117.025C117.041 30.554 116.997 30.2631 116.895 29.9902C116.794 29.7174 116.637 29.4685 116.435 29.2591C116.232 29.0498 115.989 28.8845 115.72 28.7737C115.45 28.6629 115.161 28.6089 114.87 28.6151C114.576 28.6134 114.285 28.6698 114.014 28.7811C113.742 28.8925 113.495 29.0565 113.287 29.2638C113.079 29.4711 112.914 29.7175 112.802 29.9888C112.69 30.2602 112.632 30.551 112.633 30.8447Z"
        fill="currentColor"
      />
      <path
        d="M48.5987 16.4118C49.4868 15.0737 49.5697 13.0724 49.5697 12.1842V11.8289H51.6539V16.4118H48.5987ZM47 16.4118V18.5197H47.7697V17.0987H52.5303V18.5197H53.3V16.4118H52.4592V11.1421H48.7763V12.1132C48.7763 12.9184 48.7408 15.0974 47.7342 16.4118H47Z"
        fill="currentColor"
      />
      <path
        d="M54.1338 14.0908C54.1338 15.8908 55.5311 17.2408 57.2719 17.2408C59.0127 17.2408 60.4219 15.8908 60.4219 14.0908C60.4219 12.3263 59.0127 11 57.2719 11C55.5311 11 54.1338 12.3263 54.1338 14.0908ZM54.9627 14.0908C54.9627 12.7408 55.993 11.7224 57.2719 11.7224C58.5509 11.7224 59.593 12.7408 59.593 14.0908C59.593 15.4882 58.5509 16.5066 57.2719 16.5066C55.993 16.5066 54.9627 15.4882 54.9627 14.0908Z"
        fill="currentColor"
      />
      <path
        d="M61.5235 14.1263C61.5235 15.9737 62.8854 17.2408 64.6498 17.2408C65.5617 17.2408 66.4498 16.8737 66.9709 16.2934L66.6038 15.6895C66.1301 16.2105 65.384 16.5066 64.6972 16.5066C63.3709 16.5066 62.3525 15.5237 62.3525 14.1263C62.3525 12.7053 63.3709 11.7224 64.6735 11.7224C65.3367 11.7224 65.9998 12.0303 66.4143 12.4566L66.8288 11.8526C66.2959 11.2724 65.4314 11 64.6498 11C62.8972 11 61.5235 12.2908 61.5235 14.1263Z"
        fill="currentColor"
      />
      <path d="M69.8247 17.0987H70.63V11.8526H72.8089V11.1421H67.6576V11.8526H69.8247V17.0987Z" fill="currentColor" />
      <path
        d="M73.6195 18.5908L73.3116 19.2303C73.3116 19.2303 73.7497 19.5855 74.3655 19.5855C75.159 19.5855 75.7629 19.1 76.0708 18.3421L79.0076 11.1421H78.1432L76.4261 15.4882C76.3076 15.7842 76.2247 16.1158 76.2247 16.1158H76.2011C76.2011 16.1158 76.0945 15.7842 75.9761 15.4882L74.2353 11.1421H73.3471L75.8221 17.0395L75.4668 17.8921C75.23 18.4368 74.8511 18.8632 74.33 18.8632C73.9511 18.8632 73.6195 18.5908 73.6195 18.5908Z"
        fill="currentColor"
      />
      <path d="M80.2791 17.0987H81.0844V11.8526H84.4594V17.0987H85.2646V11.1421H80.2791V17.0987Z" fill="currentColor" />
      <path
        d="M87.1831 17.0987H87.9884V14.3987H91.47V17.0987H92.2752V11.1421H91.47V13.6882H87.9884V11.1421H87.1831V17.0987Z"
        fill="currentColor"
      />
      <path
        d="M93.7886 14.0908C93.7886 15.8908 95.186 17.2408 96.9268 17.2408C98.6676 17.2408 100.077 15.8908 100.077 14.0908C100.077 12.3263 98.6676 11 96.9268 11C95.186 11 93.7886 12.3263 93.7886 14.0908ZM94.6176 14.0908C94.6176 12.7408 95.6478 11.7224 96.9268 11.7224C98.2057 11.7224 99.2478 12.7408 99.2478 14.0908C99.2478 15.4882 98.2057 16.5066 96.9268 16.5066C95.6478 16.5066 94.6176 15.4882 94.6176 14.0908Z"
        fill="currentColor"
      />
      <path
        d="M104.542 17.0987H106.815C108.153 17.0987 108.97 16.4592 108.97 15.4289C108.97 14.7303 108.532 14.15 107.763 13.9961V13.9724C108.343 13.7592 108.663 13.2737 108.663 12.6579C108.663 11.675 107.905 11.1421 106.697 11.1421H104.542V17.0987ZM105.347 13.7237V11.7579H106.567C107.407 11.7579 107.834 12.0658 107.834 12.7053C107.834 13.3447 107.419 13.7237 106.614 13.7237H105.347ZM105.347 16.4711V14.3395H106.697C107.62 14.3395 108.142 14.7184 108.142 15.4171C108.142 16.0566 107.692 16.4711 106.709 16.4711H105.347Z"
        fill="currentColor"
      />
      <rect x="0.5" y="0.5" width="151" height="47" rx="9.5" stroke="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2619_13797">
        <rect width="152" height="48" rx="10" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
