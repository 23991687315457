import type { Session } from '../users/types/session-info';
import { UserMapper } from '../users/user';
import type { ApiSession } from './session-types';

export class SessionMapper {
  static map({ user, auth, process, provider_token, provider_user_id }: ApiSession): Session {
    return {
      user: UserMapper.map(user),
      auth: {
        token: auth.token,
        refreshToken: auth.refresh_token,
      },
      process,
      providerToken: provider_token,
      providerUserId: provider_user_id,
    };
  }
}
