import useLogger from '@package/logger/src/use-logger';
import { useAuthPageAnalytics } from '@package/sdk/src/analytics';
import { PromoCodeState } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import type { AnyFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import useWatchingItems from '@/code/watching-items/use-watching-items';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { ApiError } from '@/platform/http/errors';
import useLocale from '@/platform/localization/use-locale';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import useAppSessionStorage, { SessionStorageKey } from '@/platform/session-storage/useAppSessionStorage';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { BroadcastChannelEvent } from '@/plugins/broadcast-channel';
import { useAuthRegistrationStore } from '@/stores/use-auth-registration-store';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import useParentalControlStore from '@/stores/use-parental-control-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';

import { useAdmitadUserId } from '../admitad/use-admitad-user-id';
import { usePromocodeApi } from '../bonus-programs/use-promocode-api';
import { useLikes } from '../collections/use-likes';
import { useLocalCollections } from '../collections/use-local-collections';
import { useKmzaWeboramaEvents, WeboramaAnalyticEvent } from '../kmza/use-webama-kmza';
import { NotificationLevel } from '../notifications/notification';
import type { OAuthResponse } from '../o-auth/types/o-auth';
import { useOAuthResponse } from '../o-auth/useOAuthResponse';
import { usePaymentsActions } from '../payments/use-payments-actions';
import { getPromoOfferErrorMessage } from '../promocode/get-promo-offer-error-message';
import useTvPromoPopupActions from '../tv/use-tv-promo-popup-actions';
import type { IUserCreateSession } from '../user/use-users-api';
import { useUsersApi } from '../user/use-users-api';

export interface INavigateToAuthOptions {
  offerId: string;
  offerRedirectUrl?: string;
}

interface SendToRegistrationPageOptions {
  isRedirectFromFreeEpisode?: boolean;
}

export default function useAuthActions() {
  const route = useRoute();
  const router = useRouter();
  const { translate } = useLocale();
  const app = useNuxtApp();

  const logger = useLogger('use-auth-actions');
  const authPageAnalytics = useAuthPageAnalytics(app.$analyticSender);

  const usersApi = useUsersApi();
  const promocodeApi = usePromocodeApi();

  const sessionStore = useSessionStore();
  const offersStore = useOffersStore();
  const promocodeStore = usePromocodeStore();
  const authRegStore = useAuthRegistrationStore();
  const layoutStore = useLayoutStore();
  const parentalControlStore = useParentalControlStore();

  const { isActiveSubscription } = storeToRefs(offersStore);
  const { isPartnerUser, isAuth, user } = storeToRefs(sessionStore);
  const { promocodeState } = storeToRefs(authRegStore);
  const { appliedBonusInfo, promocodeCheckInfo, savedPromocode } = storeToRefs(usePromocodeStore());
  const localStorage = useLocalStorage();

  const featuresStore = useFeaturesStore();

  const { uploadLocalWatchingItems } = useWatchingItems();

  const { sendAdmitadEvent } = useAdmitadUserId();
  const { uploadLocalCollections } = useLocalCollections();
  const { uploadLocalLikes } = useLikes();
  const { mapResponseToAuthBody } = useOAuthResponse();
  const { getTvPromoRedirectFlag, showTvPromoPopup, getTvRedirectUrl, removeFlag } = useTvPromoPopupActions();
  const { applyOfferWithPaymentSystem } = usePaymentsActions();
  const { injectWeboramaPixel, removeWeboramaSessionPixels } = useKmzaWeboramaEvents();

  const promocodeCookie = useAppCookie(CookieName.Promocode);

  const isOfferRedirectSkipped = useAppSessionStorage<boolean>(SessionStorageKey.SkipOfferRedirect, false);

  const isPromocodeCheckError = computed(() => promocodeState.value === PromoCodeState.Error);
  const isPromocodeCheckSuccess = computed(() => promocodeState.value === PromoCodeState.Success);
  const isPromocodeCheckRequestLoading = computed(() => promocodeState.value === PromoCodeState.Loading);
  const isPromocodeCheckNotSettled = computed(() => promocodeState.value === PromoCodeState.NotSettled);

  const backUrl = computed(() => route.query[RouteQuery.BackRoute] || '/');
  const isBackUrlToOffers = computed(() => backUrl.value.includes(AppRoute.Offers));

  const parseBackRoutePathAndQuery = (pathWithQuery: string) => {
    const query: Record<string, string> = {};

    const splitPath = pathWithQuery.split('?');
    let path;
    let queryString;

    if (splitPath.length === 1) {
      return {
        path: splitPath[0],
        query: {},
      };
    }

    if (splitPath.length > 1) {
      path = splitPath[0];
      queryString = splitPath[1];
    } else {
      path = undefined;
      queryString = splitPath[0];
    }

    const pairs = queryString.split('&');

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      const queryKey = decodeURIComponent(key) as string;

      query[queryKey] = decodeURIComponent(value || '');
    });

    return { path, query };
  };

  const doRedirectOfferPage = () => {
    const redirectUrl = getOfferPageRoutesToRedirect();

    return navigateTo(redirectUrl);
  };

  const getOfferPageRoutesToRedirect = (backUrl = router.currentRoute.value.fullPath) => {
    if (savedPromocode.value) {
      return {
        name: AppRoute.OffersPromo,
        params: { promocode: savedPromocode.value },
        query: { [RouteQuery.BackRoute]: backUrl },
      };
    }

    return {
      name: AppRoute.Offers,
      query: { [RouteQuery.BackRoute]: backUrl },
    };
  };

  const handlePromoOfferError = (error: ApiError) => {
    const message = getPromoOfferErrorMessage(error.message);

    // нотификашку показываем только для юзеров с подпиской, неавторизованных и партнерских
    // для юзеров без подписки сообщение об ошибке будет на странице офферов
    if (isActiveSubscription.value || !isAuth.value || isPartnerUser.value) {
      layoutStore.addNotification({
        level: NotificationLevel.Info,
        message,
        hideTimeoutMs: 3000,
        position: 'top',
      });
    }

    authRegStore.setPromocodeState(PromoCodeState.Error);
    promocodeCookie.value = '';
    promocodeStore.setSavedPromocode('');
    promocodeStore.setPromocodeCheckInfo(undefined);
    authRegStore.setPromoOfferErrorMessage(message);

    logger.error(error);
  };

  const restoreSavedPromocode = async () => {
    const promocodeQuery = route.query[RouteQuery.Promocode] as string;

    // Если нет сохраненного промокода (не в куках, не в ссылке)
    if (!promocodeCookie.value && !promocodeQuery) {
      return;
    }

    if (promocodeQuery) {
      promocodeCookie.value = promocodeQuery;
    }

    const promocode = promocodeCookie.value;

    try {
      const data = await promocodeApi.getPromocodeData(promocode);
      promocodeStore.setSavedPromocode(promocode);
      promocodeStore.setPromocodeCheckInfo(data);

      authRegStore.setPromocodeState(PromoCodeState.Success);
    } catch (error) {
      if (error instanceof ApiError) {
        handlePromoOfferError(error);
      }
    }
  };

  const checkPromocode = async (successCardNotRequiredCheckCallback?: AnyFn) => {
    try {
      promocodeState.value = PromoCodeState.Loading;

      const promocodeData = await promocodeApi.getPromocodeData(savedPromocode.value);
      promocodeStore.setPromocodeCheckInfo(promocodeData);

      const isPromocodeWithCardRequired = promocodeData.cardRequired;

      authRegStore.setPromocodeState(PromoCodeState.Success);

      promocodeCookie.value = isPromocodeWithCardRequired ? savedPromocode.value : '';

      // Если промокод успешный, но без привякиз карты
      if (!isPromocodeWithCardRequired && successCardNotRequiredCheckCallback) {
        Reflect.apply(successCardNotRequiredCheckCallback, undefined, []);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        handlePromoOfferError(error);
      }
    }
  };

  const checkQueryPromocode = (queryPromoCode: string) => {
    authRegStore.setPromocode(queryPromoCode);
    return checkPromocode();
  };

  const applyPromocode = async (): Promise<void> => {
    try {
      const appliedPromocode = await promocodeApi.applyPromocode(savedPromocode.value);
      promocodeStore.setAppliedBonusInfo(appliedPromocode);

      if (!promocodeCheckInfo.value?.cardRequired) {
        promocodeCookie.value = '';
        promocodeStore.setSavedPromocode('');
      } else {
        promocodeCookie.value = savedPromocode.value;
      }
    } catch (err) {
      authRegStore.setPromocodeState(PromoCodeState.Error);

      promocodeCookie.value = '';
      promocodeStore.setSavedPromocode('');

      logger.error(err);
    }
  };

  const applyPromocodeOfferWithPaymentSystem = async () => {
    if (isAuth.value && promocodeCheckInfo.value) {
      await applyPromocode();
    }

    const promoOffer = promocodeCheckInfo.value;

    if (!promoOffer) {
      throw new UnexpectedComponentStateError('promoOffer');
    }

    return applyOfferWithPaymentSystem(promoOffer.id);
  };

  const navigateToAuth = (options: INavigateToAuthOptions) => {
    const { offerId, offerRedirectUrl } = options;

    const backPath = route.query[RouteQuery.BackRoute] as string;
    const backUrl = backPath || offerRedirectUrl;

    // После того как юзер "явно" выбрал оффер, мы это запоминаем - и после возврата обратно, отправим сразу в ПС
    authRegStore.setIsImmediatePaymentRedirect(true);

    navigateTo({
      name: AppRoute.AuthEnter,
      query: {
        [RouteQuery.BackRoute]: backUrl,
        [RouteQuery.OfferId]: offerId,
      },
    });
  };

  const sendToRegistrationPage = (options: SendToRegistrationPageOptions = {}) => {
    const backUrl = router.currentRoute.value.fullPath;
    const query = { [RouteQuery.BackRoute]: backUrl };

    if (options.isRedirectFromFreeEpisode) {
      Reflect.set(query, RouteQuery.IsRedirectFromFreeEpisode, true);
    }

    navigateTo({
      name: AppRoute.AuthEnter,
      query,
    });
  };

  const navigateToPayment = (offerId: string) => {
    authRegStore.setIsImmediatePaymentRedirect(true);

    navigateTo({ name: AppRoute.Offers, query: { [RouteQuery.OfferId]: offerId } });
  };

  const doUpdateLocalCollections = () => {
    uploadLocalCollections();
    uploadLocalLikes();
  };

  const onDidUserCreateSession = async () => {
    // Обновляем список фича-флагов
    await restoreSavedPromocode();

    // Загружаем локальные коллекции в бек
    doUpdateLocalCollections();
    sendAdmitadEvent();

    app.$http.cache.clear();
    await offersStore.loadOffer();

    if (user.value?.id) {
      app.$yandexMetricaEventSender.setYandexMetricaUserID(user.value.id);
    }

    await uploadLocalWatchingItems();
  };

  const onDidUserLogin = async () => {
    await onDidUserCreateSession();

    injectWeboramaPixel(WeboramaAnalyticEvent.SuccessAuthorization);
  };

  const onDidUserRegister = async () => {
    await onDidUserCreateSession();

    injectWeboramaPixel(WeboramaAnalyticEvent.SuccessRegistration);
  };

  const onDidUserLogout = async () => {
    removeFlag();
    removeWeboramaSessionPixels();

    localStorage.remove(LocalStorageKey.MyChannelOnboardingPopupShown);
    localStorage.remove(LocalStorageKey.MyChannelRegistrationPopupShown);
    localStorage.remove(LocalStorageKey.MyChannelDislikePopupShown);
    localStorage.remove(LocalStorageKey.MyChannelLikePopupShown);
    localStorage.remove(LocalStorageKey.MyChannelWatchAndRatePopupShown);

    app.$http.cache.clear();

    authRegStore.reset();
    promocodeStore.reset();
    parentalControlStore.reset();
    authRegStore.setIsImmediatePaymentRedirect(false);
    await restoreSavedPromocode();
    await offersStore.loadOffer();
  };

  const validateTvPromoPopupUrl = () => {
    const tvPromoRedirectUrlAfterReg = getTvPromoRedirectFlag();

    if (!tvPromoRedirectUrlAfterReg) {
      return;
    }

    showTvPromoPopup();
    return getTvRedirectUrl();
  };

  const getValidationRestorePasswordToken = async (token: string) => {
    try {
      await usersApi.checkResetPasswordToken(token);
      return true;
    } catch (error) {
      logger.error(error);

      return false;
    }
  };

  const getPromocodeRedirectRoute = () => {
    const storedPromocode = savedPromocode.value;

    if (!storedPromocode) {
      return undefined;
    }

    // Если нет сохраненного промо-оффера
    if (!promocodeCheckInfo.value) {
      console.warn('expect to be defined promocodeCheckInfo.promoOffer');
      return undefined;
    }

    // Если нету применненого бонуса
    if (!appliedBonusInfo.value) {
      console.warn('expect to be defined appliedBonusInfo');
      return undefined;
    }

    const backRoute = getBackRouteRedirectUrl();

    if (promocodeState.value === PromoCodeState.Error) {
      authRegStore.setPromocodeState(PromoCodeState.NotSettled);

      return {
        name: AppRoute.Offers,
        query: backRoute.query,
      };
    }

    return {
      name: AppRoute.OffersPromo,
      params: { promocode: storedPromocode },
      query: backRoute.query,
    };
  };

  const getRedirectRouteTvPromoPopup = (): RouteLocationRaw | undefined => {
    const url = validateTvPromoPopupUrl();

    if (!url) {
      return undefined;
    }

    return {
      path: url,
      query: route.query,
    };
  };

  const getBackRouteRedirectUrl = () => {
    const parsedRoute = parseBackRoutePathAndQuery(String(backUrl.value));

    // @ts-expect-error
    parsedRoute.query = {
      ...parsedRoute.query,
      ...route.query,
    };

    if (isPromocodeCheckError.value) {
      Reflect.deleteProperty(parsedRoute.query, RouteQuery.OfferId);
    }

    return parsedRoute;
  };

  const getSkippedOfferRedirectRoute = () => {
    if (!isOfferRedirectSkipped.value) {
      return undefined;
    }

    isOfferRedirectSkipped.value = false;

    const backRoute = getBackRouteRedirectUrl();

    return isBackUrlToOffers.value
      ? {
          name: AppRoute.Index,
          query: route.query,
        }
      : backRoute;
  };

  const isPromocodeCardRequired = computed(() => promocodeCheckInfo.value?.cardRequired);

  const getRedirectRouteAfterRegistration = () => {
    const skippedRedirectRoute = getSkippedOfferRedirectRoute();

    if (skippedRedirectRoute) {
      return skippedRedirectRoute;
    }

    const tvRouteRedirectRoute = getRedirectRouteTvPromoPopup();
    if (tvRouteRedirectRoute) {
      return tvRouteRedirectRoute;
    }

    // Если промокод не активирован, возвращаем на страницу офферов
    if (!isPromocodeCheckSuccess.value) {
      return {
        name: AppRoute.Offers,
        query: route.query,
      };
    }

    // если промик валидный и без привязки карты показываем уведомление о применении
    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      layoutStore.addNotification({
        level: NotificationLevel.Info,
        position: 'top',
        message: translate('promocode.successEnjoyWatching'),
        hideTimeoutMs: 3000,
      });

      return parseBackRoutePathAndQuery(String(backUrl.value));
    }

    if (isPromocodeCheckSuccess.value && isPromocodeCardRequired.value) {
      return getPromocodeRedirectRoute();
    }

    throw new UnexpectedComponentStateError('getNewUserRedirectRoute');
  };

  const getRedirectRouteAfterAuthorization = async (): Promise<RouteLocationRaw | undefined> => {
    const skippedRedirectRoute = getSkippedOfferRedirectRoute();
    if (skippedRedirectRoute) {
      return skippedRedirectRoute;
    }

    const tvRouteRedirectRoute = getRedirectRouteTvPromoPopup();
    if (tvRouteRedirectRoute) {
      return tvRouteRedirectRoute;
    }

    // обычный, активная подписка
    if (isActiveSubscription.value && !isPartnerUser.value) {
      // Если вводил промокод до этого
      if (isPromocodeCheckSuccess.value && promocodeCheckInfo.value) {
        layoutStore.addNotification({
          level: NotificationLevel.Info,
          position: 'top',
          message: translate('promocode.successEnjoyWatching'),
          hideTimeoutMs: 3000,
        });
      }

      const parsedRoute = parseBackRoutePathAndQuery(String(backUrl.value));

      return isBackUrlToOffers.value ? { name: AppRoute.Index, query: route.query } : parsedRoute;
    }

    // Партнерский, активная подписка
    if (isActiveSubscription.value && isPartnerUser.value) {
      const backRoute = getBackRouteRedirectUrl();

      return isBackUrlToOffers.value
        ? {
            name: AppRoute.Index,
            query: route.query,
          }
        : backRoute;
    }

    // обычный, без подписки
    if (!isActiveSubscription.value && !isPartnerUser.value) {
      const promocodeRoute = await getPromocodeRedirectRoute();
      if (promocodeRoute) {
        return promocodeRoute;
      }

      const backRoute = getBackRouteRedirectUrl();
      return {
        name: AppRoute.Offers,
        query: backRoute.query,
      };
    }

    // Партнерский, без подписки
    if (!isActiveSubscription.value && isPartnerUser.value) {
      const backRoute = getBackRouteRedirectUrl();

      return isBackUrlToOffers.value
        ? {
            name: AppRoute.Index,
            query: route.query,
          }
        : backRoute;
    }
  };

  const doLoginUser = async () => {
    app.$emitter.emit('user.login.complete');

    const route = await getRedirectRouteAfterAuthorization();
    logger.info('Login user - redirect', route);

    await sessionStore.updateCurrentUser();

    app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Auth });

    return route;
  };

  const loginUser = async (body: Partial<IUserCreateSession>) => {
    await sessionStore.loginUser(body);

    if (isPromocodeCheckSuccess.value) {
      // Если промокод был успешно применен, забываем про всякие редиректы на ТВ попап
      await checkPromocode(removeFlag);
      await applyPromocode();
    }

    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      await sessionStore.loadSession({ forceLoadUser: false });
    }

    await featuresStore.fetchFeatures();

    authPageAnalytics.onSuccessAuthorization({ authMethod: 'login' });

    return doLoginUser();
  };

  const doRegisterUser = async () => {
    if (isPromocodeCheckSuccess.value) {
      await checkPromocode(removeFlag);
      await applyPromocode();
    }

    const route = await getRedirectRouteAfterRegistration();
    logger.info('Registered user - redirect', route);

    app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Auth });

    app.$emitter.emit('user.registration.complete');
    return route;
  };

  const registerUser = async (body: Partial<IUserCreateSession>) => {
    await sessionStore.registerUser(body);

    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      await sessionStore.loadSession({ forceLoadUser: false });
    }

    await featuresStore.fetchFeatures();

    authPageAnalytics.onSuccessRegistration({ authMethod: 'login' });

    return doRegisterUser();
  };

  const loginUserSocialAuth = async (response: OAuthResponse) => {
    const session = await sessionStore.loginUserSocialAuth(mapResponseToAuthBody(response));

    if (session.process === 'sign-in') {
      authPageAnalytics.onSuccessAuthorization({ authMethod: 'social_media_login', oauth: response.provider });
      return doLoginUser();
    }

    if (session.process === 'sign-up') {
      authPageAnalytics.onSuccessRegistration({ authMethod: 'social_media_login', oauth: response.provider });
      return doRegisterUser();
    }

    return logger.error(`unexpected session.process value ${session.process}`);
  };

  const deleteUser = async () => {
    try {
      await usersApi.deleteAccount();
      await sessionStore.deleteSession();
      await featuresStore.fetchFeatures();

      app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.DeleteAccount });

      app.$emitter.emit('user.logout.complete');
    } catch (error) {
      logger.error(error);
    } finally {
      navigateTo({ name: AppRoute.Index });
    }
  };

  const logoutUser = async () => {
    try {
      sessionStore.forgetSession();
      await featuresStore.fetchFeatures();

      app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Logout });

      app.$emitter.emit('user.logout.complete');
    } catch (e) {
      logger.error(e);
    } finally {
      authRegStore.setPromoOfferErrorMessage('');
      navigateTo({ name: AppRoute.Index });
    }
  };

  return {
    isPromocodeCheckError,
    isPromocodeCheckSuccess,
    isPromocodeCheckNotSettled,
    isPromocodeCheckRequestLoading,
    deleteUser,
    doRedirectOfferPage,
    getOfferPageRoutesToRedirect,
    restoreSavedPromocode,
    getBackRouteRedirectUrl,
    checkPromocode,
    checkQueryPromocode,
    navigateToPayment,
    navigateToAuth,
    sendToRegistrationPage,
    loginUserSocialAuth,
    applyPromocode,
    loginUser,
    logoutUser,
    registerUser,
    onDidUserLogout,
    applyPromocodeOfferWithPaymentSystem,
    getValidationRestorePasswordToken,
    onDidUserLogin,
    onDidUserRegister,
    getRedirectRouteAfterRegistration,
    getRedirectRouteAfterAuthorization,
    parseBackRoutePathAndQuery,
  };
}
