import type { DSMLAnalyticApi, DsmlValue, SendEventOptions } from '@package/dsml-js/src/core/dsml';
import useLogger from '@package/logger/src/use-logger';
import type { AnalyticEventSender, AnalyticParams } from '@package/sdk/src/analytics';
import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import type { SendEvent } from '@package/sdk/src/analytics/composables/kmza';
import { FeatureToggle } from '@package/sdk/src/api';
import { isDefined, isFunction } from '@package/sdk/src/core';
import type { H3Event } from 'h3';
import { storeToRefs } from 'pinia';
import { v4 } from 'uuid';
import type { RouteLocationNormalized } from 'vue-router';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { DsmlEventSender } from '@/code/kmza/instances/dsml-sender';
import { GoogleEventSender } from '@/code/kmza/instances/google-sender';
import { VkEventSender } from '@/code/kmza/instances/vk-sender';
import { YandexEventSender } from '@/code/kmza/instances/yandex-sender';
import { isMobileDevice } from '@/platform/base/dom';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import useEnvironment from '@/platform/environment/use-environment';
import { AppRoute } from '@/platform/router/routes';
import { AppVariation } from '@/platform/variation/interfaces';
import useHostVariation from '@/platform/variation/use-host-variation';
import useVariationVariables from '@/platform/variation/use-variation-variables';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useSessionStore } from '@/stores/use-session-store';

export type AnalyticDirectionValue = 'left' | 'right';

declare module 'nuxt/app' {
  interface NuxtApp {
    $sendAnalyticEvent: SendEvent;
    $vkEventSender: VkEventSender;
    $analyticSender: AnalyticEventSender;
    $yandexMetricaEventSender: YandexEventSender;
  }
}

interface FlocktoryApi {
  reInit?: VoidFunction;
}

declare global {
  interface Window {
    VK: VkEventSender;
    flocktory?: FlocktoryApi;
    $dsml: DSMLAnalyticApi;
  }
}

function toDSMLFormat(values: any[]): DsmlValue[] {
  return values.map(({ label, value }) => ({ property: label, value }));
}

const dict: Record<string, AnalyticEventNameNew> = {
  [AppRoute.TV]: AnalyticEventNameNew.ShowTvPage,

  [AppRoute.Search]: AnalyticEventNameNew.ShowSearchPage,

  [AppRoute.Movies]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.MovieFilter]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.Serials]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.SerialFilter]: AnalyticEventNameNew.ShowCatalogPage,
  [AppRoute.Index]: AnalyticEventNameNew.ShowMainPage,

  [AppRoute.Contacts]: AnalyticEventNameNew.ShowSupportPage,
  [AppRoute.MyCollection]: AnalyticEventNameNew.ShowCollectionsPage,
  [AppRoute.MyCollectionKinom]: AnalyticEventNameNew.ShowCollectionsPage,
  [AppRoute.MyCollectionContent]: AnalyticEventNameNew.ShowCollectionsPage,
};

export default defineNuxtPlugin(() => {
  const { dsmlPassword, dsmlLogin, isRelease, appVersion, isDev } = useEnvironment();
  const { $emitter } = useNuxtApp();
  const { getYandexMetricaId, getTopmailId } = useVariationVariables();

  const logger = useLogger('analytics');
  const req = useRequestEvent();
  const { appVariation, isWebviewMode } = useHostVariation();
  const { getVariant } = useFeaturesStore();

  const isMobile = isMobileDevice(req as unknown as H3Event);
  const sessionStore = useSessionStore();
  const { user, userIp, userIpKey } = storeToRefs(sessionStore);
  const route = useRoute();
  const router = useRouter();

  const visitorIdCookie = useAppCookie(CookieName.VisitorId, { default: () => v4() });

  const googleClientId = useAppCookie(CookieName.GoogleAnalyticsId);

  if (googleClientId.value) {
    sessionStore._gaClientId = googleClientId.value.replace(/^GA\d+.\d+./, '');
  }

  const dsml = window.$dsml as DSMLAnalyticApi;
  const dsmlEventSender = new DsmlEventSender();
  const yandexMetricaEventSender = new YandexEventSender();
  const vkEventSender = new VkEventSender();
  const googleEventSender = new GoogleEventSender();

  const yandexMetricaId = getYandexMetricaId(appVariation);
  const vkId = getTopmailId(appVariation);

  const initSenders = () => {
    const dsmlInitPromise = dsmlEventSender.initialize({
      visitorId: visitorIdCookie.value,
      isMobile,
      isRelease,
      userId: user.value?.id,
      profileId: user.value?.currentProfileId,
      appVersion,
      appVariation: appVariation === AppVariation.Ru ? 'ru' : 'am',
      userIp: userIp.value,
      userIpKey: userIpKey.value,
      password: dsmlPassword,
      login: dsmlLogin,
    });

    yandexMetricaEventSender.initialize({ id: yandexMetricaId, isDev });

    if (vkId) {
      vkEventSender.initialize({ id: vkId, isDev });
    }

    googleEventSender.initialize({ isDev });

    dsmlInitPromise.then(() => {
      sendAnalytic({
        event: AnalyticEventNameNew.AutoStartSession,
        page: getKmzaPageValue(route.name as AppRoute),
      });

      onShowPageAnalytic(route);
      router.afterEach(onShowPageAnalytic);
    });
  };

  const onFeatureFlagsUpdated = () => {
    dsml?.setFeatureFlags({
      [FeatureToggle.AllPlatformTestAA]: getVariant(FeatureToggle.AllPlatformTestAA, ''),
      [FeatureToggle.ShowAdditionalTrialCancelSubsModal]: getVariant(
        FeatureToggle.ShowAdditionalTrialCancelSubsModal,
        'group_a',
      ),
      [FeatureToggle.ActionHeaderButton]: getVariant(FeatureToggle.ActionHeaderButton, 'group_a'),
    });
  };

  const sendAnalytic: SendEvent = ({ event, page, values, options = {} }) => {
    // В Webview режиме не отправляем
    if (isWebviewMode.value) {
      return;
    }

    const analyticEvent: SendEventOptions = {
      name: event as string,
      page,
      values: isDefined(values) ? toDSMLFormat(values) : ([] as DsmlValue[]),
      options,
    };

    logger.trace('sendAnalytic', analyticEvent);

    dsmlEventSender.sendEvent(analyticEvent);
    yandexMetricaEventSender.sendYmEvent(event as AnalyticEventNameNew);
    googleEventSender.sendEvent(event as AnalyticEventNameNew, analyticEvent.values);
    vkEventSender.sendEvent(event as AnalyticEventNameNew);
  };

  const reInitFlocktory = () => {
    window.flocktory && isFunction(window.flocktory.reInit) && window.flocktory.reInit();
  };

  const onShowPageAnalytic = (to: RouteLocationNormalized) => {
    const eventName = dict[to.name as AppRoute];

    if (!eventName) {
      return;
    }

    sendAnalytic({
      event: eventName,
      page: getKmzaPageValue(to.name as AppRoute),
    });
  };

  const initInternalAnalytics = () => {
    return {
      sendAnalyticEvent: (params: AnalyticParams) => sendAnalytic(params),
      getCurrentAnalyticPageValue: () => getKmzaPageValue(route.name as AppRoute),
    };
  };

  const analyticSender = initInternalAnalytics();

  initSenders();
  onFeatureFlagsUpdated();

  router.afterEach(reInitFlocktory);

  $emitter.on('app.features.updated', onFeatureFlagsUpdated);

  return {
    provide: {
      analyticSender,
      vkEventSender,
      sendAnalyticEvent: sendAnalytic,
      yandexMetricaEventSender,
    },
  };
});
