import type { CollectionItem, CollectionStorageItem, Media, Moment } from '@package/sdk/src/api';
import { CollectionContentType, MediaContentType } from '@package/sdk/src/api';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import { storeToRefs } from 'pinia';

import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useSessionStore } from '@/stores/use-session-store';

import { useSerialApi } from '../content/use-serial-api';
import { useContentMomentsApi } from '../moments/use-content-moments-api';
import { useMovieApi } from '../movie/use-movie-api';
import { useUserCollectionApi } from '../user/use-user-collection-api';
import type { CollectionStorage, LocalCollections } from './collections';

export function useLocalCollections(): LocalCollections {
  const { isAuth } = storeToRefs(useSessionStore());
  const userCollectionApi = useUserCollectionApi();
  const { fetchMovie } = useMovieApi();
  const { fetchSerial } = useSerialApi();
  const { fetchMoment } = useContentMomentsApi();

  const { getValue, remove: removeFromLocalStorage, setValue } = useLocalStorage();

  const getStorageData = (): CollectionStorage => {
    const value = getValue(LocalStorageKey.Collection, undefined)?.value as CollectionStorage;

    if (!value) {
      return { [CollectionContentType.Content]: [], [CollectionContentType.ContentMoment]: [] };
    }

    return value;
  };

  const getStorageItems = (storage: CollectionStorage) => [
    ...storage[CollectionContentType.Content],
    ...storage[CollectionContentType.ContentMoment],
  ];

  const getLocalStorageContentDataLength = () => {
    return getStorageData()[CollectionContentType.ContentMoment].length;
  };

  const getStorageItemIds = (storage: CollectionStorage) => getStorageItems(storage).map((item) => item.id);

  const fetchMedia = ({
    mediaType,
    id,
    contentType = CollectionContentType.Content,
  }: {
    mediaType: MediaContentType;
    id: string;
    contentType: CollectionContentType;
  }) => {
    if (contentType === CollectionContentType.ContentMoment) {
      return fetchMoment(id);
    }

    return mediaType === MediaContentType.MOVIE ? fetchMovie(id) : fetchSerial(id);
  };

  const isStorageLimitReached = (limit: number) => getStorageItems(getStorageData()).length >= limit;

  const hasInStorage = (id: string) => Boolean(getStorageItems(getStorageData()).find((item) => item.id === id));

  const mapToCollectionItem = (item: Media | Moment, type: CollectionContentType) =>
    ({
      id: item.id,
      contentType: type,
      content: item,
    }) as CollectionItem;

  const clearCollection = () => {
    removeFromLocalStorage(LocalStorageKey.Collection);
  };

  const uploadLocalCollections = () => {
    if (!isAuth.value) {
      return;
    }

    const ids = getStorageItemIds(getStorageData());

    if (!ids.length) {
      return;
    }

    userCollectionApi.addItems(ids).then(() => clearCollection());
  };

  const add = (items: CollectionItem[]) => {
    const storageData = getStorageData();

    items.forEach((item: CollectionItem) => {
      storageData[item.contentType].push({
        id: item.id,
        mediaType: item.content.contentType,
      });
    });

    setValue(LocalStorageKey.Collection, { value: storageData });
    return true;
  };

  const remove = (id: string) => {
    const storageData = getStorageData();
    const contentItems = storageData[CollectionContentType.Content];
    let index = contentItems.findIndex((item) => item.id === id);

    if (index !== -1) {
      contentItems.splice(index, 1);
    } else {
      const contentMomentItems = storageData[CollectionContentType.ContentMoment];
      index = contentMomentItems.findIndex((item) => item.id === id);
      contentMomentItems.splice(index, 1);
    }

    setValue(LocalStorageKey.Collection, { value: storageData });
    return true;
  };

  const get = (contentType: CollectionContentType, page: number, limit: number): Promise<CollectionItem[]> => {
    const end = page * limit;
    const start = end - limit;
    const storageItems = getStorageData()[contentType].slice(start, end);

    if (!isAuth.value) {
      storageItems.reverse();
    }

    const items = storageItems.map((item: CollectionStorageItem) =>
      fetchMedia({ mediaType: item.mediaType!, id: item.id, contentType }).then((content: Media | Moment) =>
        mapToCollectionItem(content, contentType),
      ),
    );

    return Promise.all(items);
  };

  return {
    getLocalStorageContentDataLength,
    context: { add, remove, get },
    uploadLocalCollections,
    getStorageData,
    getStorageItems,
    isStorageLimitReached,
    hasInStorage,
  };
}
