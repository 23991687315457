import type { Media } from '../../api/content/types/media';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';

interface OnGotoSearchPagePayload {
  request: string;
  from: string;
  page: AnalyticPageName;
}

export function useSearchPageAnalytics(sender: AnalyticEventSender) {
  const onSearchRequestEntering = (searchText: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSearchRequestEntering,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.From,
          value: sender.getCurrentAnalyticPageValue(),
        },
        {
          label: AnalyticPayloadLabelName.Request,
          value: searchText,
        },
      ],
    });
  };

  const onClickSearchRequestCancel = (request: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSearchRequestCancel,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: request,
        },
      ],
    });
  };

  const onSearchResultsDisplayed = (searchText: string, contents: Media[], isSemanticRequest: boolean) => {
    const ids: string[] = [];

    contents.forEach((content) => {
      ids.push(content.id);
    });

    sender.sendAnalyticEvent({
      event: isSemanticRequest
        ? AnalyticEventNameNew.AutoSearchResultsFullDisplayed
        : AnalyticEventNameNew.AutoSearchResultsPreviewDisplayed,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: searchText,
        },
        {
          label: AnalyticPayloadLabelName.ListItemId,
          value: ids,
        },
      ],
    });
  };

  const onShowSearchPage = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowSearchPage,
      page: AnalyticPageName.Search,
    });
  };

  const onGotoSearchPage = ({ request, from, page }: OnGotoSearchPagePayload) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoSearchPage,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: request,
        },
        {
          label: AnalyticPayloadLabelName.From,
          value: from,
        },
      ],
    });
  };

  const onAutoSearchHistoryDisplayed = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.AutoSearchHistoryDisplayed,
      page: AnalyticPageName.Search,
    });
  };

  const onClickSearchHistoryItem = (request: string, position: number) => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ClickSearchHistoryItem,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: request,
        },
        {
          label: AnalyticPayloadLabelName.Position,
          value: position,
        },
      ],
    });
  };

  const onClickSearchVoiceMode = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ClickSearchVoiceMode,
      page: AnalyticPageName.Search,
    });
  };

  const onAutoSearchVoiceError = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.AutoSearchVoiceError,
      page: AnalyticPageName.Search,
    });
  };

  const onAutoSearchVoiceSuccess = (request: string) => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.AutoSearchVoiceSuccess,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: request,
        },
      ],
    });
  };

  return {
    onSearchRequestEntering,
    onClickSearchRequestCancel,
    onSearchResultsDisplayed,
    onShowSearchPage,
    onGotoSearchPage,
    onAutoSearchHistoryDisplayed,
    onClickSearchHistoryItem,
    onClickSearchVoiceMode,
    onAutoSearchVoiceError,
    onAutoSearchVoiceSuccess,
  };
}
