import { BonusProgram } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useSessionStore } from '@/stores/use-session-store';

const nonAuthRoutes = new Set([
  AppRoute.Auth,
  AppRoute.AuthEnter,
  AppRoute.AuthEnterPassword,
  AppRoute.AuthRestorePassword,
  AppRoute.AuthPasswordRestoreEmail,
  AppRoute.AuthPasswordLinkExpired,
  AppRoute.AuthPasswordRestorePhone,
]);

const authRoutes = new Set([
  AppRoute.Account,
  AppRoute.AccountProfiles,
  AppRoute.AccountBonuses,
  AppRoute.AccountBonusesPromocode,
  AppRoute.AccountLanguage,
  AppRoute.AccountDevices,
  AppRoute.AccountProfiles,
  AppRoute.AccountSubscriptionInfo,
  AppRoute.AccountSubscriptionManage,
  AppRoute.AccountInfo,
  AppRoute.ThankYou,
]);

const allProtectedRoutes = new Set([...authRoutes.values(), ...nonAuthRoutes.values()]);

const getRedirectRouteAuthUserWithPromocode = (route: RouteLocationNormalized): RouteLocationRaw | undefined => {
  const query = route.query;

  if (!query[RouteQuery.Promocode]) {
    return undefined;
  }

  return {
    name: AppRoute.AccountBonuses,
    query: {
      ...query,
      [RouteQuery.BonusProgram]: BonusProgram.PromoCode,
    },
  };
};

export default defineNuxtRouteMiddleware((route) => {
  const { isAuth } = storeToRefs(useSessionStore());
  const backUrl = route.query[RouteQuery.BackRoute] as string;

  const isProtectedRoute = allProtectedRoutes.has(route.name as AppRoute);

  if (!isProtectedRoute) {
    return;
  }

  if (isAuth.value && nonAuthRoutes.has(route.name as AppRoute)) {
    const promocodeLkRoute = getRedirectRouteAuthUserWithPromocode(route);

    if (promocodeLkRoute) {
      return navigateTo(promocodeLkRoute);
    }

    return navigateTo(
      backUrl
        ? { path: backUrl, query: route.query }
        : {
            name: AppRoute.Index,
            query: route.query,
          },
    );
  } else if (!isAuth.value && authRoutes.has(route.name as AppRoute)) {
    return navigateTo(
      backUrl
        ? { path: backUrl }
        : {
            name: AppRoute.AuthEnter,
            query: {
              [RouteQuery.BackRoute]: route.fullPath,
            },
          },
    );
  }
});
