import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

const MOBILE_HEADER_HEIGHT = 56;

export default function useScreenOrientation() {
  const { height: _height, width: _width } = useWindowSize();

  const height = computed(() => _height.value - MOBILE_HEADER_HEIGHT);
  const width = computed(() => _width.value);

  const orientation = computed<'horizontal' | 'vertical'>(() =>
    width.value > height.value ? 'horizontal' : 'vertical',
  );

  return {
    orientation,
  };
}
